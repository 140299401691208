import { type Cluster, ClusterType } from "@doitintl/cmp-models";
import { CircularProgress } from "@mui/material";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";

interface ClusterThreeDotsMenuProps {
  cluster: Cluster;
  onTestConnection?: () => Promise<void>;
  onActivateCluster?: () => Promise<void>;
  onDeactivateCluster?: () => Promise<void>;
  onDeleteCluster?: () => void;
  onEditCluster?: () => void;
  loading?: boolean;
}

const PRIVATE_CLUSTER_TOOLTIP =
  "The control plane of this cluster is not accessible through the internet. Private control planes are currently not supported.";

const ClusterThreeDotsMenu = ({
  cluster,
  onTestConnection,
  onActivateCluster,
  onDeactivateCluster,
  onDeleteCluster,
  onEditCluster,
  loading = false,
}: ClusterThreeDotsMenuProps) => {
  const options: ThreeDotsMenuOption[] = [
    {
      label: "Test connection",
      tooltip: cluster.isPrivate ? PRIVATE_CLUSTER_TOOLTIP : "Test cluster connection",
      tooltipPlacement: "left",
      key: "test-connection",
      dataCy: "test-cluster-connection",
      action: onTestConnection,
      disabled: cluster.isPrivate,
    },
    cluster.isActivated
      ? {
          label: "Deactivate",
          tooltip: "Deactivate cluster",
          tooltipPlacement: "left",
          key: "deactivate",
          dataCy: "deactivate-cluster",
          action: onDeactivateCluster,
        }
      : {
          label: "Activate",
          tooltip: cluster.isPrivate ? PRIVATE_CLUSTER_TOOLTIP : "Activate cluster",
          tooltipPlacement: "left",
          key: "activate",
          dataCy: "activate-cluster",
          action: onActivateCluster,
          disabled: cluster.isPrivate,
        },
  ];

  if (cluster.type === ClusterType.SELF_MANAGED) {
    options.push(
      {
        label: "Delete",
        tooltip: "Delete cluster",
        tooltipPlacement: "left",
        key: "delete",
        dataCy: "delete-cluster",
        action: onDeleteCluster,
      },
      {
        label: "Edit",
        tooltip: "Edit cluster",
        tooltipPlacement: "left",
        key: "edit",
        dataCy: "edit-cluster",
        action: onEditCluster,
      }
    );
  }

  if (loading) {
    return <CircularProgress size={24} />;
  }

  return <ThreeDotsMenu options={options} size="small" closeAfterSelect />;
};

export default ClusterThreeDotsMenu;
