import { type ReactNode } from "react";

import { Link } from "react-router-dom";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { dateFormatDayMonthYear } from "../../../utils/dateTimeFormats";
import { useThreads } from "../../Threads/api";
import { useInsightsContext } from "../context";
import { type Insight } from "../types";
import { isIrrelevantOrDismissed, isOptimized, totalCustomProblems, totalPotentialDailySavings } from "../utils";
import { CloudProvidersIcons } from "./CloudProvidersIcons";
import { CustomProblemsPreview } from "./CustomProblemsPreview";
import { InsightSourceLabel } from "./InsightSourceLabel";
import { InsightStatusInfoBar } from "./InsightStatusInfoBar";
import { PotentialSavingsPreview } from "./PotentialSavingsPreview";

export const Preview = ({ insight, isListView }: { insight: Insight; isListView: boolean }) => {
  const { savingsPeriod, calculateSavingsForSelectedPeriod } = useInsightsContext();
  const { customer: originalCustomer } = useCustomerContext();
  const { threads } = useThreads();
  const theme = useTheme();
  const routeMatchURL = useRouteMatchURL();
  const tagsToShow = insight.categories?.length ? insight.categories : insight.otherTags;

  let updatePublishLabel;
  if (insight.providerId === "custom") {
    const publishedFormatted = DateTime.fromJSDate(new Date(insight.customInsightAttributes?.published ?? "")).toFormat(
      dateFormatDayMonthYear
    );
    updatePublishLabel = `Published on ${publishedFormatted}`;
  } else {
    const lastUpdatedFormatted = DateTime.fromJSDate(new Date(insight.lastUpdated)).toFormat(dateFormatDayMonthYear);
    updatePublishLabel = `Last checked on ${lastUpdatedFormatted}`;
  }

  let component: ReactNode;

  if (insight.status === "error" || isOptimized(insight) || isIrrelevantOrDismissed(insight)) {
    if (isListView) {
      if (insight.status === "error") {
        component = <Chip label="Something went wrong" variant="filled" size="small" color="error" />;
      } else if (isIrrelevantOrDismissed(insight)) {
        component = <Chip label="Dismissed" variant="filled" size="small" color="info" />;
      } else if (isOptimized(insight)) {
        component = <Chip label="Optimized" variant="filled" size="small" color="success" />;
      }
    } else {
      component = <InsightStatusInfoBar insight={insight} />;
    }
  } else {
    const potentialDailySavings = totalPotentialDailySavings(insight);
    const customProblems = totalCustomProblems(insight);

    const resultPreviews: ReactNode[] = [];

    if (potentialDailySavings !== null) {
      resultPreviews.push(
        <PotentialSavingsPreview
          savings={calculateSavingsForSelectedPeriod(potentialDailySavings)}
          savingsPeriod={savingsPeriod}
        />
      );
    }

    if (customProblems !== null) {
      resultPreviews.push(
        <CustomProblemsPreview
          value={customProblems}
          unitLongSingular={insight?.results?.customProblems?.unitLongSingular || ""}
          unitLongPlural={insight?.results?.customProblems?.unitLongPlural || ""}
        />
      );
    }

    component = (
      <Stack direction="row" spacing={2} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
        {resultPreviews.map((preview, i) => (
          <Stack key={i}>{preview}</Stack>
        ))}
      </Stack>
    );
  }
  const matchingThreads =
    threads?.filter(
      (thread) =>
        thread.source === "insight" &&
        thread.sourceData.insightKey === insight.key &&
        thread.sourceData.providerID === insight.providerId
    ) || [];

  const mainBlock = (
    <>
      <Typography variant={isListView ? "h3" : "h1"}>
        {insight.isInternal ? <Chip label="DoiT internal" color="primary" size="small" /> : null} {insight.title}
      </Typography>

      <Typography sx={{ mt: 1.5 }} color="text.secondary" variant="body1">
        {insight.shortDescription}
      </Typography>

      <Box sx={{ my: isListView ? 2.25 : 3.5 }}>{component}</Box>
    </>
  );

  return (
    <>
      {isListView ? (
        <Link
          to={`${routeMatchURL}/${insight.providerId}/${insight.key}`}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {mainBlock}
        </Link>
      ) : (
        mainBlock
      )}

      <InsightSourceLabel insight={insight} />

      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        divider={<Divider orientation="vertical" sx={{ height: "22px" }} />}
        sx={{ color: "text.secondary" }}
        height="28px"
      >
        <Stack direction="row" spacing={0.75} alignItems="center">
          <Typography variant="body2">{updatePublishLabel}</Typography>
        </Stack>

        <Typography variant="body2">{tagsToShow?.join(", ")}</Typography>

        <CloudProvidersIcons cloudTags={insight.cloudTags} />

        {isListView && matchingThreads?.length > 0 ? (
          <Link
            to={`/customers/${originalCustomer.id}/threads?filter=${insight.providerId}/${insight.key}`}
            style={{ color: theme.palette.primary.main, fontSize: "14px" }}
          >
            Tracked by {matchingThreads.length} {matchingThreads.length === 1 ? "thread" : "threads"}
          </Link>
        ) : null}
      </Stack>
    </>
  );
};
