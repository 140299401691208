import { type InsightStatusChangesModel } from "@doitintl/cmp-models";

import { type RequestConfig } from "../../Pages/CloudAnalytics/generateReport/types";

export type Status = "success" | "pending" | "error" | "running";

export type ProviderTag = "aws" | "gcp" | "azure" | "snowflake";

export enum Category {
  FinOps = "FinOps",
  OperationalExcellence = "Operational excellence",
  PerformanceEfficiency = "Performance efficiency",
  Reliability = "Reliability",
  Security = "Security",
  Sustainability = "Sustainability",
}
export const ALL_PROVIDERS: ProviderTag[] = ["aws", "gcp", "azure", "snowflake"];

export const ALL_CATEGORIES: Category[] = Object.values(Category);

export type BreakdownData = {
  value: number;
  baseValue: number;
  dimensionValues: string[];
};

export type Breakdown = {
  dimensions: string[];
  data: BreakdownData[];
};

export type PotentialDailySavings = {
  isOptimized: boolean;
  breakdown: Breakdown | null;
};

export type CustomProblems = {
  isOptimized: boolean;
  unitShortSingular: string;
  unitShortPlural: string;
  unitLongSingular: string;
  unitLongPlural: string;
  breakdown: Breakdown | null;
};

export type Results = {
  isRelevant: boolean;
  potentialDailySavings: PotentialDailySavings | null;
  customProblems: CustomProblems | null;
  reportConfig: RequestConfig | null;
};

export type CustomInsightAttributes = {
  published: string;
  publishingUserEmail: string;
  showPublishingUser: boolean;
};

export type Insight = {
  providerId: string;
  key: string;
  canBeIndividuallyExecuted: boolean;
  customerId: string;
  status: Status;
  lastUpdated: string;
  title: string;
  shortDescription: string;
  detailedDescriptionMdx: string;
  cloudTags: ProviderTag[];
  otherTags: string[];
  categories: Category[];
  isInternal: boolean;
  supportCategory: string;
  results: Results | null;
  reportUrl: string;
  customInsightAttributes: CustomInsightAttributes | null;
  userStatusChanges: InsightStatusChangesModel | null;
};

export type SavingsPeriod = "daily" | "monthly" | "yearly";

type NonEntitledInsightsResult = {
  // Number of insights that are relevant to a customer (but may not have any value and be already optimized)
  numberOfRelevantInsights: number;

  // Number of insights that are relevant but not yet optimized
  numberOfActionableInsights: number;

  // Total value (in USD in the case of potentialDailySavings or simple integer count for customProblems)
  value: number;
};

export type NonEntitledInsightsResults = {
  potentialDailySavings: NonEntitledInsightsResult;
  customProblems: NonEntitledInsightsResult;
};

export type InsightResultsResponse = {
  results: Insight[];
  nonEntitledResultsSummary: NonEntitledInsightsResults;
};
