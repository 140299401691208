import { type ReactElement } from "react";

import { Link as RouterLink } from "react-router-dom";
import {
  type BQLensBillingMode,
  BQLensBillingModes,
  type BQLensMeasurement,
  type BQLensMetric,
  BQLensMetrics,
  BQLensOptimizerModes,
  type BQLensTimeFrame,
  editionModes,
  type SpendProfile,
  SuperQueryModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

import { type CloudConnectType } from "../../../Context/customer/CloudCOnnectContext";
import { BillingMode } from "../../../Pages/Customer/constants";
import {
  billingProjectUserFlatRate,
  billingProjectUserOnDemandOptions,
  datasetTableProjectFlatRateFilterOptions,
  datasetTableProjectOnDemandFilterOptions,
  scanPrice,
  scanTb,
  slots,
  storagePrice,
  storageTB,
} from "./constants";

type GetDataProps = {
  customerId: string;
  metricType: BQLensMetric;
  measurement: BQLensMeasurement;
  selectedTimeframe: BQLensTimeFrame;
  selectedBillingMode: BQLensBillingMode;
};

export const makeBaseRollUpsQuery = ({
  customerId,
  selectedTimeframe,
  metricType,
  measurement,
  selectedBillingMode,
}: {
  customerId: string;
  metricType: BQLensMetric;
  measurement: BQLensMeasurement;
  selectedTimeframe: BQLensTimeFrame;
  selectedBillingMode: BQLensBillingMode;
}) => {
  if (
    selectedBillingMode === BQLensBillingModes.onDemand &&
    ["project", "dataset", "table"].includes(metricType) &&
    [scanPrice, scanTb].includes(measurement)
  ) {
    return getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection("on-demand")
      .doc(customerId)
      .collection("rollUps")
      .doc(selectedTimeframe)
      .collection(metricType)
      .doc(measurement);
  }

  if (
    selectedBillingMode === BQLensBillingModes.onDemand &&
    ["project", "dataset", "table"].includes(metricType) &&
    [storagePrice, storageTB].includes(measurement)
  ) {
    return getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection("output")
      .doc(customerId)
      .collection("rollUps")
      .doc(selectedTimeframe)
      .collection(metricType)
      .doc(measurement);
  }

  if (["project", "dataset", "table"].includes(metricType)) {
    return getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection("output")
      .doc(customerId)
      .collection("rollUps")
      .doc(selectedTimeframe)
      .collection(metricType)
      .doc(measurement);
  }

  if ([storagePrice, storageTB].includes(measurement)) {
    return getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection("output")
      .doc(customerId)
      .collection("rollUps")
      .doc(selectedTimeframe)
      .collection(metricType)
      .doc(measurement);
  }

  if ([scanPrice, scanTb].includes(measurement) && selectedBillingMode === BQLensBillingModes.onDemand) {
    return getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection("on-demand")
      .doc(customerId)
      .collection("rollUps")
      .doc(selectedTimeframe)
      .collection(metricType)
      .doc(measurement);
  }

  if (measurement === slots && editionModes.includes(selectedBillingMode)) {
    return getCollection(SuperQueryModel)
      .doc("simulation-recommender")
      .collection(selectedBillingMode)
      .doc(customerId)
      .collection("rollUps")
      .doc(selectedTimeframe)
      .collection(metricType)
      .doc(measurement);
  }

  throw new Error("Invalid tree path happened that should have never happen :scream:");
};

const getData = async (props: GetDataProps) => makeBaseRollUpsQuery(props).get();

export const checkDataExists = async (props: GetDataProps): Promise<boolean> => {
  const data = await getData(props);
  return data.exists();
};

export const getFilteredUnits = async ({
  unitsToCheck,
  ...rest
}: Omit<GetDataProps, "measurement"> & { unitsToCheck: Array<BQLensMeasurement> }): Promise<BQLensMeasurement[]> => {
  const existCheckResults = await Promise.all(
    unitsToCheck.map((measurement) => checkDataExists({ ...rest, measurement }))
  );

  return unitsToCheck.filter((item, index) => existCheckResults[index]);
};

export const getFilteredUnitsBasedOnMetricValue = async ({
  metricType,
  selectedBillingMode,
  customerId,
  selectedTimeframe,
}: {
  metricType: BQLensMetric;
  selectedBillingMode: BQLensBillingMode;
  customerId: string;
  selectedTimeframe: BQLensTimeFrame;
}): Promise<BQLensMeasurement[]> => {
  const metricTypeEnum = metricType as BQLensMetrics;

  let unitsToCheck: Array<BQLensMeasurement>;

  if (
    [BQLensMetrics.project, BQLensMetrics.dataset, BQLensMetrics.table].includes(metricTypeEnum) &&
    selectedBillingMode === BQLensBillingModes.onDemand
  ) {
    unitsToCheck = datasetTableProjectOnDemandFilterOptions;
  } else if (
    [BQLensMetrics.project, BQLensMetrics.dataset, BQLensMetrics.table].includes(metricTypeEnum) &&
    editionModes.includes(selectedBillingMode)
  ) {
    unitsToCheck = datasetTableProjectFlatRateFilterOptions;
  } else if (
    [BQLensMetrics.billingProject, BQLensMetrics.user].includes(metricTypeEnum) &&
    selectedBillingMode === BQLensBillingModes.onDemand
  ) {
    unitsToCheck = billingProjectUserOnDemandOptions;
  } else if (
    [BQLensMetrics.billingProject, BQLensMetrics.user].includes(metricTypeEnum) &&
    editionModes.includes(selectedBillingMode)
  ) {
    unitsToCheck = billingProjectUserFlatRate;
  } else {
    throw new Error("Invalid metric type and billing mode combination. $s{metricType} $s{selectedBillingMode}");
  }

  return getFilteredUnits({
    unitsToCheck,
    customerId,
    metricType,
    selectedTimeframe,
    selectedBillingMode,
  });
};

export const getServiceAccountsBQFinOps = (cloudConnect: CloudConnectType) =>
  cloudConnect?.filter((sa) => sa.cloudPlatform === "google-cloud" && sa.categoriesStatus?.["bigquery-finops"] === 1);

export const useSelectStyles = makeStyles(() => ({
  select: {
    fontSize: 14,
  },
}));

/** * for BQ Dashboard Alert ***/

export const alertActionButton = (to: string, text: string): ReactElement => (
  <Button
    component={RouterLink}
    to={to}
    color="inherit"
    sx={{
      "&:hover": {
        backgroundColor: "transparent",
      },
    }}
  >
    {text}
  </Button>
);

export const loaderBox: ReactElement = (
  <Box p={1} pl={2}>
    <CircularProgress size={22} />
  </Box>
);

export const processAfterScanMsg = (stage3Progress: number): ReactElement => (
  <>
    The scan is completed, and <strong>{stage3Progress}%</strong> of your data was already processed. You are almost
    there!
  </>
);

export const dataScanMsg = (scanProgress: number): ReactElement => (
  <>
    We have started to analyze your historical usage. So far we have processed <strong>{scanProgress}%</strong>. In the
    meantime, you can explore our training perks.
  </>
);

const getOnDemandOptions = (metric: BQLensMetric): Array<BQLensMeasurement> => {
  switch (metric) {
    case BQLensMetrics.dataset:
    case BQLensMetrics.project:
    case BQLensMetrics.table:
      return datasetTableProjectOnDemandFilterOptions;
    default:
      return billingProjectUserOnDemandOptions;
  }
};

const getEditionOptions = (metric: BQLensMetric): Array<BQLensMeasurement> => {
  switch (metric) {
    case BQLensMetrics.billingProject:
    case BQLensMetrics.user:
      return billingProjectUserFlatRate;
    case BQLensMetrics.dataset:
    case BQLensMetrics.project:
    case BQLensMetrics.table:
      return datasetTableProjectFlatRateFilterOptions;
    default:
      throw new Error(`Unsupported metric editions: ${metric}`);
  }
};

export const getMeasurementsForMetric = (billingMode: BillingMode, metric: BQLensMetric): Array<BQLensMeasurement> => {
  switch (billingMode) {
    case BillingMode.onDemand:
      return getOnDemandOptions(metric);

    case BillingMode.standardEdition:
    case BillingMode.enterpriseEdition:
    case BillingMode.enterprisePlusEdition:
      return getEditionOptions(metric);

    default:
      throw new Error(`Unsupported billing mode: ${billingMode as string}`);
  }
};

export function setInitialBillingMode(spendProfile: SpendProfile): BillingMode {
  if (spendProfile.isOnDemand) {
    return BillingMode.onDemand;
  }

  if (spendProfile.isStandardEdition) {
    return BillingMode.standardEdition;
  }

  if (spendProfile.isEnterpriseEdition) {
    return BillingMode.enterpriseEdition;
  }

  return BillingMode.enterprisePlusEdition;
}

export const isEdition = (mode: BQLensOptimizerModes): boolean =>
  mode === BQLensOptimizerModes.standardEdition ||
  mode === BQLensOptimizerModes.enterpriseEdition ||
  mode === BQLensOptimizerModes.enterprisePlusEdition;
