import { type CloudflowCreationStatus } from "@doitintl/cmp-models";
import { AppBar, Button, Divider, Skeleton, Stack, Tooltip, useTheme } from "@mui/material";
import { Box } from "@mui/system";

import doitLogo from "../../../../assets/doit-logo-hero-square.svg";
import { cloudflowTexts } from "../../../../assets/texts";
import Title from "../Common/Title";
import { StatusChip } from "./StatusChip";

type Props = {
  flowTouched: boolean;
  cloudflowName?: string;
  cloudflowStatus?: CloudflowCreationStatus;
  onUpdateName: (title: string) => void;
  onSaveCloudflow: () => void;
  onEditCloudflow: () => void;
  onRunCloudflow: () => void;
  onUnpublishCloudflow: () => void;
  onPublishCloudflow: () => void;
  isPublished: boolean;
  hasActiveExecutions?: boolean;
  disabled?: boolean;
  cloudflowLoading: boolean;
  cloudflowUpdateLoading: boolean;
  onClose: () => void;
  onBlurTitle: () => void;
};

const WorkflowActions = ({
  isPublished,
  hasActiveExecutions,
  onSaveCloudflow,
  onEditCloudflow,
  onRunCloudflow,
  onUnpublishCloudflow,
  onPublishCloudflow,
  onClose,
  cloudflowUpdateLoading,
  flowTouched,
}: Pick<
  Props,
  | "isPublished"
  | "hasActiveExecutions"
  | "onSaveCloudflow"
  | "onEditCloudflow"
  | "onRunCloudflow"
  | "onPublishCloudflow"
  | "onUnpublishCloudflow"
  | "onClose"
  | "cloudflowUpdateLoading"
  | "flowTouched"
>) => (
  <Stack direction="row" gap={1}>
    {isPublished ? (
      <>
        <Button variant="contained" color="primary" onClick={onEditCloudflow}>
          {cloudflowTexts.EDIT}
        </Button>
        <Tooltip
          title={
            hasActiveExecutions
              ? "This CloudFlow is currently executing and cannot be triggered again until the current execution is complete."
              : ""
          }
        >
          <Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={onRunCloudflow}
              disabled={hasActiveExecutions}
              sx={{
                height: "100%",
              }}
            >
              {cloudflowTexts.RUN}
            </Button>
          </Box>
        </Tooltip>
        <Button variant="outlined" color="error" onClick={onUnpublishCloudflow}>
          {cloudflowTexts.UNPUBLISH}
        </Button>
      </>
    ) : (
      <>
        <Button variant="contained" color="primary" onClick={onPublishCloudflow} disabled={cloudflowUpdateLoading}>
          {cloudflowTexts.PUBLISH}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onSaveCloudflow}
          disabled={cloudflowUpdateLoading || !flowTouched}
        >
          {cloudflowTexts.SAVE}
        </Button>
      </>
    )}
    <Button variant="text" onClick={onClose}>
      {cloudflowTexts.CLOSE}
    </Button>
  </Stack>
);

const Topbar = ({
  cloudflowName = "",
  cloudflowStatus,
  onUpdateName,
  hasActiveExecutions,
  isPublished,
  onSaveCloudflow,
  onEditCloudflow,
  onPublishCloudflow,
  onRunCloudflow,
  onUnpublishCloudflow,
  onClose,
  cloudflowLoading,
  cloudflowUpdateLoading,
  flowTouched,
  onBlurTitle,
}: Props) => {
  const theme = useTheme();

  return (
    <AppBar
      elevation={0}
      position="sticky"
      sx={{ background: theme.palette.general.backgroundDefault, mt: -7, width: "100%" }}
    >
      <Stack>
        <Stack direction="row" justifyContent="space-between" py={1} px={2} sx={{ width: "100%" }}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            {cloudflowLoading ? (
              <>
                <Skeleton variant="circular" width={36} height={36} />
                <Skeleton variant="text" width={200} height={36} />
              </>
            ) : (
              <>
                <Box component="img" src={doitLogo} sx={{ height: "36px", width: "36px" }} />
                <Title
                  title={cloudflowName}
                  onUpdateTitle={onUpdateName}
                  placeholder="Add CloudFlow name"
                  disabled={cloudflowLoading}
                  onBlur={onBlurTitle}
                />
                <StatusChip status={cloudflowStatus} />
              </>
            )}
          </Stack>
          {cloudflowLoading ? (
            <Skeleton variant="rectangular" width={120} height={36} />
          ) : (
            <WorkflowActions
              hasActiveExecutions={hasActiveExecutions}
              flowTouched={flowTouched}
              onEditCloudflow={onEditCloudflow}
              onPublishCloudflow={onPublishCloudflow}
              onRunCloudflow={onRunCloudflow}
              onUnpublishCloudflow={onUnpublishCloudflow}
              isPublished={isPublished}
              onSaveCloudflow={onSaveCloudflow}
              onClose={onClose}
              cloudflowUpdateLoading={cloudflowUpdateLoading}
            />
          )}
        </Stack>
        <Divider sx={{ width: "100%" }} />
      </Stack>
    </AppBar>
  );
};

export default Topbar;
