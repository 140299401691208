import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

import { useLocalStorage } from "../../Components/hooks/storageHooks";
import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useInsights } from "./api";
import { type Insight, type SavingsPeriod } from "./types";

type InsightsContext = {
  savingsPeriod: SavingsPeriod;
  setSavingsPeriod: (savingsPeriod: SavingsPeriod) => void;
  calculateSavingsForSelectedPeriod: (dailySavings: number) => number;
  selectedInsight: Insight | null;
  setSelectedInsight: Dispatch<SetStateAction<Insight | null>>;
};

const InsightsContext = createContext({} as InsightsContext);

type Parameters = {
  providerId: string;
  insightKey: string;
};

const Loader = ({ color }: { color?: string }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
    <SyncLoader size={10} color={color} loading />
  </Box>
);

export function InsightsContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const { providerId, insightKey } = useParams<Parameters>();

  const { insights, isFetching, error } = useInsights();

  const errSnackbar = useErrorSnackbar(10);

  const theme = useTheme();

  const [savingsPeriod, setSavingsPeriod] = useLocalStorage<SavingsPeriod>("insights.savingsPeriod", "monthly");
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null);

  let calculateSavingsForSelectedPeriod: InsightsContext["calculateSavingsForSelectedPeriod"];

  switch (savingsPeriod) {
    case "yearly":
      calculateSavingsForSelectedPeriod = (savings) => savings * 365.25;
      break;
    case "monthly":
      calculateSavingsForSelectedPeriod = (savings) => savings * 30.4;
      break;
    case "daily":
      calculateSavingsForSelectedPeriod = (savings) => savings;
  }

  useEffect(() => {
    if (!(providerId && insightKey) || !insights) {
      return;
    }

    const matchingInsight = (insights || []).find(
      (insight) => insight.providerId === providerId && insight.key === insightKey
    );

    setSelectedInsight(matchingInsight || null);
  }, [insights, isFetching, providerId, insightKey]);

  useEffect(() => {
    if (!error) {
      return;
    }
    errSnackbar(error.message || "Failed to load insights");
  }, [error, errSnackbar]);

  if (isFetching) {
    return <Loader color={theme.palette.primary.main} />;
  }

  return (
    <InsightsContext.Provider
      value={{
        savingsPeriod,
        setSavingsPeriod,
        calculateSavingsForSelectedPeriod,
        selectedInsight,
        setSelectedInsight,
      }}
    >
      {children}
    </InsightsContext.Provider>
  );
}

export const useInsightsContext = () => useContext(InsightsContext);
