import { type ChangeEvent, useEffect, useMemo, useState } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import {
  AssetModel,
  AssetSettingModel,
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudProjectStandalone,
  AssetTypeGoogleCloudStandalone,
  AssetTypeGSuite,
  AssetTypeLooker,
  AssetTypeMicrosoftAzure,
  AssetTypeMicrosoftAzureStandalone,
  AssetTypeOffice365,
  AssetTypeSnowflake,
  type DataConnectorSnowflakeModel,
} from "@doitintl/cmp-models";
import { getBatch, getCollection } from "@doitintl/models-firestore";
import { AppBar, Box, Grid, MenuItem, TextField } from "@mui/material";
import { amber } from "@mui/material/colors";
import { type Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import groupBy from "lodash/groupBy";

import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import Hide from "../../Components/HideChildren/Hide";
import { TraceRoute } from "../../Components/Routes/TraceRoutes";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import { SideNavigationLayout } from "../../Navigation/Components/ThirdLevelNav/SideNavigationLayout";
import { type PageId } from "../../Navigation/config/pages";
import { type AnyAsset, type Asset } from "../../types";
import { assetTypeName, getCurrencyByCode } from "../../utils/common";
import mixpanel from "../../utils/mixpanel";
import {
  buildGoogleCloudAssetCounts,
  filterRemoveAwsAssetsWithoutWorkload,
  getDefaultAssetType,
  isAWSAsset,
} from "./assetUtils";
import AddTagDialog from "./Dialogs/AddTagDialog";
import AssignEntityDialog from "./Dialogs/AssignEntityDialog";
import AwsAssetPage from "./EditAsset/AwsAssetPage";
import GCAssetPage from "./GCTab/GCAssetPage/GCAssetPage";
import { getSnowflakeConfigDocs } from "./hooks";
import { useHandshakes } from "./Hooks/useHandshakes";
import AmazonWebServicesAssetsTab from "./Tabs/AmazonWebServicesAssetsTab";
import GCAssetSubmenu, { type GCPTableMode } from "./Tabs/components/GCAssetSubmenu";
import GoogleAssetSelector from "./Tabs/components/GoogleAssetSelector";
import GoogleCloudAssetsTab from "./Tabs/GoogleCloudAssetsTab";
import GSuiteAssetsTab from "./Tabs/GSuiteAssetsTab";
import LookerAssetsTab from "./Tabs/LookerAssetsTab";
import MicrosoftAzureAssetsTab from "./Tabs/MicrosoftAzureAssetsTab";
import Office365AssetsTab from "./Tabs/Office365AssetsTab";
import SnowflakeAssetsTab from "./Tabs/SnowflakeAssetsTab";
import { extractLookerSkus } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardContent: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    textField: {
      width: 160,
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        width: 300,
      },
    },
    warningIcon: {
      color: amber[500],
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    activeRoot: {},
  })
);

const ALL_ENTITIES = "all-entities";

const assetTypes = [
  {
    key: AssetTypeAmazonWebServices,
    label: "Amazon Web Services",
    tab: AmazonWebServicesAssetsTab,
  },
  {
    key: AssetTypeGSuite,
    label: "Google Workspace",
    tab: GSuiteAssetsTab,
  },
  {
    key: AssetTypeGoogleCloud,
    label: "Google Cloud",
    tab: GoogleCloudAssetsTab,
  },
  {
    key: AssetTypeMicrosoftAzure,
    label: "Microsoft Azure",
    tab: MicrosoftAzureAssetsTab,
  },
  {
    key: AssetTypeOffice365,
    label: "Office 365",
    tab: Office365AssetsTab,
  },
  {
    key: AssetTypeLooker,
    label: assetTypeName(AssetTypeLooker),
    tab: LookerAssetsTab,
  },
  {
    key: AssetTypeSnowflake,
    label: "Snowflake",
    tab: SnowflakeAssetsTab,
  },
] as const;

const availableForFlexsaveStandalone = new Set([AssetTypeAmazonWebServices, AssetTypeGoogleCloud]);

type Params = {
  tab: (typeof assetTypes)[number]["key"];
  assetId: string;
  url: string;
};

const Assets = ({ pageId }: { pageId: PageId }) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch<Params>();
  const {
    customer,
    isProductOnlyCustomer,
    assets,
    assetsLoading,
    contracts,
    contractsLoading,
    appendLoadedAssetTypes,
    entities,
  } = useCustomerContext();
  const [handshakes] = useHandshakes();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const isEntitledSnowflakeConnect = useIsFeatureEntitled("snowflake:connect");
  const [tabIndex, setTabIndex] = useState<string | null>(null);
  const [tabLabel, setTabLabel] = useState<string | null>(null);
  const [selectedEntity, setSelectedEntity] = useState<string>(ALL_ENTITIES);
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [assignEntityDialog, setAssignEntityDialog] = useState<boolean>(false);
  const [addTagDialog, setAddTagDialog] = useState<boolean>(false);
  const [tableMode, setTableMode] = useState<GCPTableMode>("google-cloud");
  const [sfConfigs, setSfConfigs] = useState<DataConnectorSnowflakeModel[]>([]);
  const getAssets = (selectedEntity: string) =>
    Object.entries(assets).flatMap(([entityId, entityAssets]) => {
      if (selectedEntity === ALL_ENTITIES || selectedEntity === entityId) {
        return entityAssets.filter((asset) => !asset.id.includes("doitintl-fs-"));
      }

      return [];
    });

  const getContracts = (selectedEntity: string) =>
    contractsLoading
      ? undefined
      : contracts.filter((contract) => selectedEntity === ALL_ENTITIES || selectedEntity === contract.entity?.id);

  const entityAssets = getAssets(selectedEntity);
  const entityContracts = getContracts(selectedEntity);
  const assetsByType = groupBy(entityAssets, (asset) => asset.data.type);
  const contractsByType = entityContracts ? groupBy(entityContracts, "type") : entityContracts;

  useEffect(() => {
    if (match.params.tab) {
      setTabIndex(match.params.tab);
      const tabLabel = assetTypes.find((assetType) => assetType.key === match.params.tab)?.label || null;
      setTabLabel(tabLabel);
    }
  }, [match.params.tab]);

  useEffect(() => {
    if (!match.params.tab) {
      const assetsKeys = Object.keys(assets).filter((key) => key !== "_unassigned");
      const noAssets = !assetsLoading && !assetsKeys.length;
      const assetsRootUrl = `/customers/${customer.id}/assets`;

      if (noAssets) {
        history.replace(`${assetsRootUrl}/${assetTypes[0].key}`);
        return;
      }

      const tab = getDefaultAssetType(assets);
      history.replace(`${assetsRootUrl}/${tab}`);
    }

    appendLoadedAssetTypes([
      AssetTypeGoogleCloudProject,
      AssetTypeGoogleCloudDirect,
      AssetTypeGoogleCloudStandalone,
      AssetTypeGoogleCloudProjectStandalone,
      AssetTypeMicrosoftAzureStandalone,
    ]);
  }, [appendLoadedAssetTypes, assets, assetsLoading, contractsLoading, customer.id, history, match.params.tab]);

  useEffect(() => {
    mixpanel.track("assets.open");
  }, []);

  useEffect(() => {
    if (isEntitledSnowflakeConnect) {
      const unsubscribe = getSnowflakeConfigDocs(customer.id, (res) => {
        setSfConfigs(res);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [customer.id, isEntitledSnowflakeConnect]);

  const handleSelectedEntityChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSelectedEntity(target.value);
  };

  const handleAssignToEntity = (asset: AnyAsset) => () => {
    setAssignEntityDialog(true);
    setSelectedAsset(asset);
  };

  const onAddTag = (asset: Asset) => {
    setAddTagDialog(true);
    setSelectedAsset(asset);
  };

  const handleRemoveAsset = (asset: Asset) => () => getCollection(AssetModel).doc(asset.id).delete();

  const handleCloseDialog = (dialog: "assignEntityDialog" | "addTagDialog") => () => {
    if (dialog === "assignEntityDialog") {
      setAssignEntityDialog(false);
      setSelectedAsset(null);
    } else {
      setAddTagDialog(false);
      setSelectedAsset(null);
    }
  };

  const saveTags = async (tags: string[]) => {
    if (!selectedAsset) {
      return;
    }

    const batch = getBatch();
    batch.update(getCollection(AssetSettingModel).doc(selectedAsset.id), { tags });
    batch.update(getCollection(AssetModel).doc(selectedAsset.id), { tags });
    await batch.commit();
  };

  const getSuggestedTags = (assets: Asset[]) => {
    const allTags = assets.reduce((acc, curr) => {
      (curr.data.tags ?? []).forEach((tag) => acc.add(tag));
      return acc;
    }, new Set<string>());
    return [...allTags];
  };

  const billingProfileField = useMemo(
    () => (
      <TextField
        select
        fullWidth
        name="selectedEntity"
        label="Billing Profile"
        className={pageId !== "asset:gcp" ? classes.textField : ""}
        value={selectedEntity}
        onChange={handleSelectedEntityChange}
        SelectProps={
          pageId !== "asset:gcp"
            ? {
                MenuProps: {
                  MenuListProps: {
                    dense: true,
                  },
                },
              }
            : {}
        }
        margin="normal"
        variant="outlined"
      >
        <MenuItem value={ALL_ENTITIES}>Show All</MenuItem>
        {entities.map((entity) => (
          <MenuItem
            key={entity.id}
            value={entity.id}
            classes={{
              root: classes.activeRoot,
            }}
          >
            {`${entity.priorityId} (${getCurrencyByCode(entity.currency)}) - ${entity.name}`}
          </MenuItem>
        ))}
      </TextField>
    ),
    [classes.activeRoot, classes.textField, entities, pageId, selectedEntity]
  );

  const pageHeaderActions = useMemo(
    () => (
      <Grid container direction="row-reverse" spacing={1}>
        <Hide smDown>
          <Grid item>{billingProfileField}</Grid>
        </Hide>
      </Grid>
    ),
    [billingProfileField]
  );

  const suggestedTags = useMemo(
    () =>
      Object.keys(assetsByType).reduce<Partial<Record<AssetType, string[]>>>((acc, assetTypeKey) => {
        acc[assetTypeKey] = getSuggestedTags(assetsByType[assetTypeKey]);

        return acc;
      }, {}),
    [assetsByType]
  );

  const getTypeAssets = (assetTypeKey: AssetType): AnyAsset[] => {
    switch (assetTypeKey) {
      case AssetTypeAmazonWebServices: {
        const awsAssets = [
          ...(assetsByType[assetTypeKey] ?? []),
          ...(assetsByType[AssetTypeAwsStandalone] ?? []),
        ].filter(isAWSAsset);
        return filterRemoveAwsAssetsWithoutWorkload(awsAssets);
      }
      case AssetTypeGoogleCloud: {
        const directAndStandaloneGCP = [
          ...(assetsByType[AssetTypeGoogleCloudProject] ?? []),
          ...(assetsByType[AssetTypeGoogleCloudStandalone] ?? []),
          ...(assetsByType[AssetTypeGoogleCloudProjectStandalone] ?? []),
        ];

        return [
          ...(assetsByType[assetTypeKey] ?? []),
          ...directAndStandaloneGCP,
          ...(assetsByType[AssetTypeGoogleCloudDirect] ?? []),
        ] as const;
      }
      case AssetTypeMicrosoftAzure:
        return [
          ...(assetsByType[AssetTypeMicrosoftAzureStandalone] ?? []),
          ...(assetsByType[assetTypeKey] ?? []),
        ] as const;
      default:
        return assetsByType[assetTypeKey] ?? [];
    }
  };

  const getVirtualAssetsCount = (assetType: (typeof assetTypes)[number]) => {
    if (assetType.key === AssetTypeLooker) {
      return extractLookerSkus(contracts).length;
    }
    if (assetType.key === AssetTypeSnowflake) {
      return sfConfigs.length;
    }

    const typeAssets = getTypeAssets(assetType.key);
    const n = (typeAssets || []).length;
    if (assetType.key === AssetTypeAmazonWebServices) {
      return n + (handshakes?.length ?? 0);
    }

    return n;
  };

  const sideTabs = () => {
    if (!tabIndex) {
      return;
    }

    const items = assetTypes
      .filter((assetType) => {
        if (isProductOnlyCustomer && (customer.enabledFlexsave?.AWS || customer.enabledFlexsave?.GCP)) {
          return availableForFlexsaveStandalone.has(assetType.key);
        }
        if (assetType.key === AssetTypeSnowflake) {
          return isEntitledSnowflakeConnect;
        }
        return true;
      })
      .map((assetType) => {
        const displayGoogleSubTabs =
          assetType.key === AssetTypeGoogleCloud &&
          pageId === "assets:all" &&
          match.params.tab === AssetTypeGoogleCloud;
        const n = getVirtualAssetsCount(assetType);
        const googleCloudAssetCounts = buildGoogleCloudAssetCounts(assetsByType, displayGoogleSubTabs);
        return {
          key: `tab-${assetType.key}`,
          value: assetType.key,
          label: n > 0 ? `${assetType.label} (${n})` : assetType.label,
          to: `/customers/${customer.id}/assets/${assetType.key}`,
          children: displayGoogleSubTabs ? (
            <GCAssetSubmenu
              setTableMode={(newAssetType) => {
                setTableMode(newAssetType);
              }}
              counts={googleCloudAssetCounts}
              tableMode={tableMode}
              isStandalone={isProductOnlyCustomer || customer.enabledSaaSConsole?.GCP}
            />
          ) : undefined,
        };
      });

    return {
      value: tabIndex,
      items,
      extraWidth: true,
    };
  };

  const tabulatedAssets = [AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeLooker, AssetTypeSnowflake];
  const showAssetPage = pageId === "asset:page" && tabulatedAssets.includes(match.params.tab);

  const assetPages = {
    [AssetTypeAmazonWebServices]: <AwsAssetPage suggestedTags={suggestedTags[AssetTypeAmazonWebServices] ?? []} />,
    [AssetTypeGoogleCloud]: userRoles && (
      <GCAssetPage
        suggestedTags={{
          [AssetTypeGoogleCloud]: suggestedTags[AssetTypeGoogleCloud],
          [AssetTypeGoogleCloudDirect]: suggestedTags[AssetTypeGoogleCloudDirect],
          [AssetTypeGoogleCloudProject]: suggestedTags[AssetTypeGoogleCloudProject],
          [AssetTypeGoogleCloudProjectStandalone]: suggestedTags[AssetTypeGoogleCloudProjectStandalone],
        }}
      />
    ),
  };

  return (
    <>
      {showAssetPage && assetPages[match.params.tab]}
      {!showAssetPage && (
        <SideNavigationLayout data={sideTabs()} title="Assets" sx={{ width: "220px" }}>
          <>
            {match.params.tab === AssetTypeGoogleCloud && (
              <GoogleAssetSelector
                data={buildGoogleCloudAssetCounts(assetsByType, true)}
                handleSelect={(newAssetType) => {
                  setTableMode(newAssetType);
                }}
                tableMode={tableMode}
              />
            )}
            <Box width="100%">
              <DoitConsoleTitle pageName="Assets" pageLevel1={tabLabel} />
              {!tabulatedAssets.includes(match.params.tab) && pageHeaderActions}
              {match.params.tab ? (
                <>
                  {assetTypes.map((assetType) => {
                    const filteredAssets = getTypeAssets(assetType.key);
                    return (
                      <TraceRoute
                        key={`route-${assetType.key}`}
                        exact
                        path={`/customers/${customer.id}/assets/${assetType.key}/:assetId?/:transfer?`}
                        render={() => (
                          <assetType.tab
                            assets={assetsLoading ? undefined : (filteredAssets as any[])}
                            contracts={contractsByType ? (contractsByType[assetType.key] ?? []) : contractsByType}
                            highlight={match.params.assetId}
                            onAssignToEntity={handleAssignToEntity}
                            onRemoveAsset={handleRemoveAsset}
                            onAddTag={onAddTag}
                            assetTypeKey={assetType.key}
                            tableMode={tableMode}
                            sfConfigs={sfConfigs}
                          />
                        )}
                      />
                    );
                  })}
                </>
              ) : null}
              {selectedAsset && (
                <>
                  {assignEntityDialog && (
                    <AssignEntityDialog onClose={handleCloseDialog("assignEntityDialog")} asset={selectedAsset} />
                  )}
                  {addTagDialog && (
                    <AddTagDialog
                      onClose={handleCloseDialog("addTagDialog")}
                      onSave={saveTags}
                      selectedAsset={selectedAsset}
                    />
                  )}
                </>
              )}
            </Box>
          </>
        </SideNavigationLayout>
      )}
      <AppBar enableColorOnDark position="static" color="inherit" />
    </>
  );
};

export default Assets;
