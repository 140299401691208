import {
  type Dispatch,
  Fragment,
  type SetStateAction,
  type TransitionEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocation } from "react-router-dom";
import {
  Aggregator,
  AnalyticsDataSource,
  AnalyticsResourceType,
  ComparativeFeature,
  CurrencyCodes,
  Feature,
  type LimitAggregation,
  Metadata,
  Metric,
  type MetricFilter,
  Renderer,
  type ReportConfig,
  RunReportChannelStatus,
  WidgetState,
} from "@doitintl/cmp-models";
import { FirstPageRounded as ArrowLeft, LastPageRounded as ArrowRight } from "@mui/icons-material/";
import {
  Box,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import intersection from "lodash/intersection";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";

import { handleResponseError } from "../../api/axiosClient";
import { useApiContext } from "../../api/context";
import { cloudAnalyticsText, comparativeDialogText, metricText, reportText } from "../../assets/texts";
import { reportTxt } from "../../assets/texts/CloudAnalytics";
import { useCreateWidgetReportData } from "../../Components/Dashboard/Analytics/hooks";
import FilterDialog from "../../Components/Dialogs/CloudAnalytics/FilterDialog";
import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import useReportMixpanel from "../../Components/hooks/cloudAnalytics/reports/useReportMixpanel";
import useReportSaver from "../../Components/hooks/cloudAnalytics/reports/useReportSaver";
import { useMetricFormatter } from "../../Components/hooks/cloudAnalytics/useMetricFormatter";
import useFormatter from "../../Components/hooks/useFormatter";
import { useGotoParentUrl } from "../../Components/hooks/useGotoParentUrl";
import { useInterval } from "../../Components/hooks/useInterval";
import { useKeyPress } from "../../Components/hooks/useKeyPress";
import useQueryString from "../../Components/hooks/useQueryString";
import useReportCache, { ReportCacheType } from "../../Components/hooks/useReportCache";
import useUpdateEffect from "../../Components/hooks/useUpdateEffect";
import { CircularProgressLoader } from "../../Components/Loader";
import LoadingButton from "../../Components/LoadingButton";
import MixpanelButton from "../../Components/MixpanelButton";
import SaveAsComponent from "../../Components/SaveComponents/SaveAsComponent";
import SaveDialog from "../../Components/SaveComponents/SaveDialog";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../Components/SimpleDialog";
import SplitCostModal from "../../Components/SplitCostModal";
import TemplateCreate from "../../Components/TemplateCreate/TemplateCreate";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useHotkeyContext } from "../../Context/HotkeyContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { useUnsavedChanges } from "../../Context/UnsavedChangesContext";
import { type AttributionWRef, type MetadataOption, type MetricWSnap } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { CSPCustomerID } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import mixpanel from "../../utils/mixpanel";
import { usePrevious } from "../../utils/usePrevious";
import { type KeyTypeValues } from "./api";
import { buildAttributionGroupsPayload } from "./attributionGroups/attributionGroupsPayload";
import { type AttributionGroupWithRef } from "./attributionGroups/types";
import { useAnalyticsContext } from "./CloudAnalyticsContext";
import {
  useCreateTemplateContext,
  useMetricSplitsContext,
  useReportConfig,
  useReportContext,
  useReportDimensionsContext,
  useReportSaverContext,
  useReportTemplateContext,
} from "./Context";
import { LimitsFilterDialog } from "./dialogs/limitsFilterDialog";
import { type Limit } from "./dialogs/limitsFilterDialog/limitsFilterDialog";
import { MetricFiltersDialog } from "./dialogs/metricFiltersDialog/metricFiltersDialog";
import TreemapConfirmDialog from "./dialogs/treemapConfirmDialog/TreemapConfirmDialog";
import { refreshWidget } from "./handlers/updateWidgets";
import Info from "./info";
import { ReportConfigKind } from "./reducers/ReportConfigReducer";
import { initialReportState, ReportStateKind, reportStateReducer } from "./reducers/ReportStateReducer";
import GoogleSheetsDialog from "./renderers/GoogleSheetsDialog";
import PlotRenderer from "./renderers/PlotRenderer";
import ReportHeader from "./report/actionBar/ReportHeader";
import DraggableGroupWrapper from "./report/config/DraggableGroupWrapper";
import { useCreateRunReportChannel } from "./report/hooks";
import { cancelQuery, executeQueryRequest, type QueryRequest, QueryType } from "./report/ReportQuery";
import {
  accountsParam,
  attributionParam,
  calculatedMetricParamForReport,
  extractCloudProviders,
  parseFilters,
  positionsParams,
  splitsParam,
} from "./report/ReportQueryUtils";
import ReportRightMenu from "./report/ReportRightMenu";
import ReportViewSelect from "./report/ReportViewSelect";
import { type PopoverAnchorEl } from "./report/types";
import { updateReportStats } from "./report/updateReportStats";
import ReportData, { type ColKeySort } from "./ReportData";
import { useStyles } from "./ReportStyles";
import {
  comparativeValues,
  defaultMetricFilterOperator,
  ErrorCode,
  FixedFilters,
  getBaseMetricLabels,
  getMetricOptions,
  getMetricsCount,
  getReportRowSort,
  getTimeSettings,
  isComparative,
  isEditor,
  isTable,
  IsValidAggregator,
  MenuProps,
  Positions,
  REPORT_LEFT_PANEL_BOTTOM_HEIGHT,
  REPORT_LEFT_PANEL_WIDTH,
  REPORT_LEFT_PANEL_WIDTH_CLOSED,
  timeIntervalOptions,
} from "./utilities";

type Props = {
  attributions: AttributionWRef[];
  handleRevertReport: () => void;
  attributionGroups: AttributionGroupWithRef[];
  setRawReportData: Dispatch<SetStateAction<any[][]>>;
};

const Report = ({ attributions, handleRevertReport, attributionGroups, setRawReportData }: Props) => {
  const { report } = useReportContext();
  const api = useApiContext();
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  const classes = useStyles();
  const qs = useQueryString();
  const { onOpen: showSharedSnackbar } = useSnackbar();

  const { isMobile: smDown } = useFullScreen();
  const { current: touched } = useRef<Set<string>>(new Set());
  const { customer } = useCustomerContext();
  const { transforms, loadAnalyticsLabels } = useAnalyticsContext();
  const [saveAsComponentVisible, setSaveAsComponentVisible] = useState(false);
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [reportError, setReportError] = useState<{ code: ErrorCode; message?: string }>();
  const reportMixpanel = useReportMixpanel();
  const { metricSplits, metricSplitModalData, deleteMetricSplits, metricSplitsInit } = useMetricSplitsContext();
  const { reportConfig, dispatchReportConfig, reportData, reportDataWForecasts } = useReportConfig();
  const {
    name,
    currency,
    aggregator,
    renderer,
    features,
    timeInterval,
    rowOrder,
    colOrder,
    comparative,
    metric,
    calculatedMetric,
    extendedMetric,
    forecastSettings,
    metricFilters,
    excludePartialData,
    includeCredits,
    limitAggregation,
    extendedMetrics,
    timeRangeOption,
    includeSubtotals,
    dataSource,
    showValuesInFilterSearchResults,
    datahubMetrics,
  } = reportConfig;

  const isEntitledAnalyticsForecasts = useIsFeatureEntitled("analytics:forecasts");

  const handleReportError = useCallback(
    (code: ErrorCode) => {
      setReportError({ code });
    },
    [setReportError]
  );
  const {
    dashboardsContextLoaded,
    handleCacheReport,
    isReportCacheLoading,
    isReportCacheRecentlyRefreshed,
    rawCacheReport,
    removeReportFromCache,
    reportCacheType,
    userCachedReportWidget,
  } = useReportCache({
    report,
    isTable: isTable(renderer),
    handleReportError,
  });
  const [isReportCachedBtnLoading, setIsReportCachedBtnLoading] = useState(false);
  const [isRefreshCacheBtnLoading, setIsRefreshCacheBtnLoading] = useState(false);

  const [selectedCalcMetric, setSelectedCalcMetric] = useState<MetricWSnap | null>(null);
  const [switchedToCalcMetric, setSwitchedToCalcMetric] = useState(false);
  const [switchedToExtendMetric, setSwitchedToExtendMetric] = useState(false);
  const [calcMetricsDialogOpen, setCalcMetricsDialogOpen] = useState(false);
  const [metricFiltersDialogOpen, setMetricFiltersDialogOpen] = useState(false);
  const [limitsFilterDialogOpen, setLimitsFilterDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    reportSaver: { hasUnsavedChanges, initialConfig },
  } = useReportSaverContext();
  const [forceRender, setForceRender] = useState(false);
  const createRunReportChannel = useCreateRunReportChannel({ status: RunReportChannelStatus.INITIALIZED });

  // extended metrics can be "cost", "usage" or "datahub"
  const extendedMetricMode = useMemo(() => {
    if (!extendedMetric) {
      return null;
    }

    const extMetric = extendedMetrics?.find((m) => m.key === extendedMetric);
    if (extMetric) {
      return extMetric.type;
    }

    const datahubMetric = datahubMetrics?.find((m) => m.key === extendedMetric);
    if (datahubMetric) {
      return "datahub";
    }

    return "cost";
  }, [extendedMetric, extendedMetrics, datahubMetrics]);

  const [colKeySort, setColKeySort] = useState<ColKeySort>();
  const cachedReportData = useCreateWidgetReportData(rawCacheReport, colKeySort, "");
  const isCachedReport =
    reportCacheType === ReportCacheType.WidgetCached ||
    (reportCacheType === ReportCacheType.UserCached && userCachedReportWidget?.state !== WidgetState.Failed);
  const loadStartTime = useRef(0);
  const [showReportCacheBtn, setShowReportCacheBtn] = useState(false);
  const [selected, setSelected] = useState<MetadataOption | undefined>();
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [treemapDialogOpen, setTreemapDialogOpen] = useState(false);
  const [comparativeDialogOpen, setComparativeDialogOpen] = useState(false);
  const [reportStates, dispatchReportStates] = useReducer(reportStateReducer, initialReportState);
  const { queryRunning, querySuccess, reportReady, mounted, isInit } = reportStates;
  const [showRunReportAlert, setShowRunReportAlert] = useState(false);
  const [isTreemapExact, setIsTreemapExact] = useState(false);
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled, state: hotKeysEnabled } = useHotkeyContext();
  const runShortcutKey = useKeyPress("r", { shiftKey: true });
  const tableViewShortcutKey = useKeyPress("t");
  const heatmapViewShortcutKey = useKeyPress("h");
  const [prevView, setPrevView] = useState(Renderer.TABLE);
  const [googleDialogOpen, setGoogleDialogOpen] = useState(false);
  // indicate case changing from "none" to some comparative type, enforce new request and not just reportData update
  const [comparativeRequest, setComparativeRequest] = useState(false);
  const [timeDimensionsReady, setTimeDimensionsReady] = useState(false);
  const colSortDisabled = !!(renderer === Renderer.TREEMAP || (comparative && isComparative(comparative)));
  const [countFieldMissing, setCountFieldMissing] = useState(false);
  const [hideLimitAlert, setHideLimitAlert] = useState(false);
  const [reportLeftDrawerOpen, setReportLeftDrawerOpen] = useState(true);
  const [leftDrawerTransitioning, setLeftDrawerTransitioning] = useState(false);
  const [renderKey, setRenderKey] = useState(0);
  const reportHeightRef = useRef<HTMLDivElement>(null);
  const [reportHeight, setReportHeight] = useState(reportHeightRef?.current?.offsetTop ?? 0);
  const [filterPopoverOpenedOnce, setFilterPopoverOpenedOnce] = useState(false);
  const {
    dimensions,
    handleDimensionSelect,
    handleAggregatorChange,
    handleMetricFiltersChange,
    handleLimitsFilterChange,
    handleDeleteDimension,
    handleReportFilterChange,
    handleTreemapChange,
    resetLimit,
    handleMetricChange,
    handleSortComparativeDimensions,
    handleAttributionMdSelect,
    handleDimensionLabelsChange,
  } = useReportDimensionsContext();
  const { createTemplateData } = useCreateTemplateContext();
  const { reportTemplateId } = useReportTemplateContext();
  const controller = useRef(new AbortController());

  const parentUrl = useMemo(() => `/customers/${customer.id}/analytics/reports`, [customer.id]);
  const goBackToReports = useGotoParentUrl(parentUrl);

  useEffect(() => {
    setForceRender(false);
    setIsRefreshCacheBtnLoading(false);
  }, [rawCacheReport]);

  useEffect(() => {
    if (reportTemplateId) {
      mixpanel.track("analytics.templates.open", { reportTemplateId });
    }
  }, [reportTemplateId]);

  useEffect(() => {
    if (isReportCacheLoading) {
      loadStartTime.current = performance.now();
    } else if (isCachedReport) {
      const loadEndTime = performance.now();
      const totalDurationMs = Math.round(loadEndTime - loadStartTime.current);
      mixpanel.track("analytics.reports.cached-data-loaded", {
        reportId: report.snapshot.id,
        totalDurationMs,
      });
    }
  }, [isCachedReport, isReportCacheLoading, report.snapshot.id]);

  const handleFilterPopoverOpened = useCallback(() => {
    setFilterPopoverOpenedOnce(true);
  }, []);

  useEffect(() => {
    if (showValuesInFilterSearchResults && filterPopoverOpenedOnce) {
      loadAnalyticsLabels();
    }
  }, [loadAnalyticsLabels, filterPopoverOpenedOnce, showValuesInFilterSearchResults]);

  const dimensionsCols = useMemo(
    () => dimensions?.filter((md) => md._position === Positions.COL && md._visible),
    [dimensions]
  );

  const isCurrentUserEditor = isEditor(currentUser.email, report.data);

  const { handleSave, handleSaveName, handleSaveAsComponentSave, handleResetReport } = useReportSaver();

  useInterval(() => {
    setReportHeight(reportHeightRef?.current?.offsetTop ?? 0);
  }, undefined);

  const reRender = useCallback(() => {
    if (!isTable(renderer)) {
      setRenderKey((key) => key + 1);
    }
  }, [renderer]);

  const onTransitionEnd = useCallback(
    (transitionEvent: TransitionEvent<HTMLDivElement>) => {
      if (transitionEvent.propertyName !== "width") {
        return;
      }
      setLeftDrawerTransitioning(false);
      reRender();
    },
    [reRender]
  );

  useEffect(() => {
    if (customer.treemapRenderConfig === "exact") {
      setIsTreemapExact(true);
    }
  }, [customer]);

  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const showForecast = useMemo(
    () => !!reportConfig.forecastSettings && !!isEntitledAnalyticsForecasts,
    [isEntitledAnalyticsForecasts, reportConfig.forecastSettings]
  );
  const trends = useMemo(
    () => intersection(features, [Feature.TREND_UP, Feature.TREND_DOWN, Feature.TREND_NONE]),
    [features]
  );

  // Popover states
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<PopoverAnchorEl | null>({
    widget: null,
    mdUnused: null,
    mdRow: null,
    mdCol: null,
    mdCount: null,
  });

  const handleClose = useCallback(() => {
    setFilterDialogOpen(false);
    setLimitsFilterDialogOpen(false);
    setTreemapDialogOpen(false);
    setGoogleDialogOpen(false);
    setComparativeDialogOpen(false);
    setTimeout(() => {
      setSelected(undefined);
    }, 300);
    setCalcMetricsDialogOpen(false);
    setOpenNameDialog(false);
    if (!createTemplateData.isCreateTemplateOpen) {
      setHotKeysEnabled();
    }
  }, [setHotKeysEnabled, createTemplateData.isCreateTemplateOpen]);

  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();

  const isCSP = useMemo(() => customer.id === CSPCustomerID, [customer]);

  const showLimitAlert = useMemo(() => {
    if (!dimensions || hideLimitAlert || !reportStates.finishedFirstQueryRun) {
      return false;
    }

    if (metricFilters.length > 0 && metricFilters.some((mf) => mf.metric !== metric)) {
      return true;
    }

    return dimensions?.some(
      (md) =>
        md._limit &&
        md._limit > 0 &&
        md._limitMetric !== null &&
        md._limitMetric !== undefined &&
        md._limitMetric !== metric
    );
  }, [dimensions, hideLimitAlert, reportStates.finishedFirstQueryRun, metricFilters, metric]);

  const cloudProviders = useMemo(() => extractCloudProviders(dimensions), [dimensions]);

  const getMetricFilter = () => {
    if (metricFilters?.length > 0) {
      return metricFilters[0];
    }
    return {
      metric,
      operator: defaultMetricFilterOperator,
      values: [],
    };
  };

  const updateMetricFilters = useCallback(
    (filters: MetricFilter[]) => {
      dispatchReportConfig({ payload: { metricFilters: filters } });
      handleMetricFiltersChange(filters);
    },
    [handleMetricFiltersChange, dispatchReportConfig]
  );

  const handleMetricFilters = (mf: MetricFilter) => {
    if (
      metricFilters === undefined ||
      metricFilters.length === 0 ||
      mf.metric !== metricFilters[0].metric ||
      mf.operator !== metricFilters[0].operator ||
      mf.values.length !== metricFilters[0].values.length ||
      mf.values.findIndex((v, i) => v !== metricFilters[0].values[i]) !== -1
    ) {
      updateMetricFilters([mf]);
    }
    setMetricFiltersDialogOpen(false);
    setSelected(undefined);
  };

  const onLimitsFilterConfirm = (limits: Limit[], aggregation?: LimitAggregation) => {
    handleLimitsFilterChange(limits);
    dispatchReportConfig({ payload: { limitAggregation: aggregation } });
    setLimitsFilterDialogOpen(false);
  };

  const handleDelete = useCallback(
    (dimensions: MetadataOption[]) => {
      handleDeleteDimension(dimensions);
      dimensions.forEach((d) => {
        deleteMetricSplits(d);
      });
      const lastDimension = dimensions.at(-1);
      if (lastDimension?.data?.type === Metadata.METRIC && lastDimension?._metricFilter) {
        dispatchReportConfig({ payload: { metricFilters: [] } });
      }
    },
    [handleDeleteDimension, deleteMetricSplits, dispatchReportConfig]
  );

  const handleCancelLimits = (item?: MetadataOption) => {
    if (item) {
      handleDelete([item]);
    }
    setLimitsFilterDialogOpen(false);
    setSelected(undefined);
  };

  useEffect(() => {
    if (userCachedReportWidget?.state === WidgetState.Processing) {
      setIsRefreshCacheBtnLoading(true);
    }
  }, [userCachedReportWidget?.state]);

  useEffect(() => {
    if (isCachedReport && cachedReportData) {
      reportData.current = cachedReportData;
      setReportError(undefined);
      dispatchReportStates({
        type: ReportStateKind.FINISHED_RUNNING,
        payload: {
          querySuccess: true,
          reportReady: true,
          queryRunning: false,
          finishedFirstQueryRun: true,
        },
      });
      setIsReportCachedBtnLoading(false);
    }
  }, [isCachedReport, cachedReportData, reportData]);

  const handleRun = useCallback(
    async (partialConfig?: Partial<QueryRequest>) => {
      if (queryRunning || (!hasUnsavedChanges && isCachedReport)) {
        return;
      }

      if (!partialConfig) {
        partialConfig = {};
      }

      dispatchReportStates({ type: ReportStateKind.RUNNING, payload: {} });

      // Check for override of config state
      const scopedExcludePartialData = partialConfig.excludePartialData ?? excludePartialData;
      const scopedIncludeCredits = partialConfig.includeCredits ?? includeCredits;
      const scopedExtendedMetric = partialConfig.extendedMetric ?? extendedMetric;
      const scopedMetric = partialConfig.metric ?? metric;

      const timeSettings = getTimeSettings(timeRangeOption);
      const { rows, cols, count } = positionsParams(dimensions ?? []);
      if (!timeSettings || (aggregator === Aggregator.COUNT && !count)) {
        // invalid custom time range
        dispatchReportStates({ payload: { queryRunning: false } });
        // invalid query, aggregation count requires count param
        aggregator === Aggregator.COUNT && !count && setCountFieldMissing(true);
        return;
      }
      timeSettings.interval = timeInterval;

      const accounts = accountsParam(dimensions ?? [], cloudProviders);
      const filteredAttributions = attributionParam(dimensions ?? [], attributions);
      const cMetric = calculatedMetricParamForReport(attributions, calculatedMetric?.data);

      const filters = parseFilters(dimensions ?? []);

      const attributionGroupsPayload = buildAttributionGroupsPayload({
        attributions,
        dimensions: dimensions ?? [],
        attributionGroups,
      });

      controller.current = new AbortController();

      const channelId = await createRunReportChannel();

      const request: QueryRequest = {
        type: QueryType.REPORT,
        id: report.snapshot.id,
        accounts,
        timeSettings,
        rows,
        cols,
        count,
        attributions: filteredAttributions,
        attributionGroups: attributionGroupsPayload,
        filters,
        metricFilters,
        currency: currency ?? null,
        metric: scopedMetric,
        extendedMetric: scopedExtendedMetric ?? undefined,
        trends,
        forecast: showForecast,
        forecastSettings: isEntitledAnalyticsForecasts ? forecastSettings : null,
        cloudProviders: cloudProviders ?? null,
        comparative: comparative && isComparative(comparative) ? comparative : null,
        calculatedMetric: cMetric ?? null,
        excludePartialData: scopedExcludePartialData,
        includeCredits: scopedIncludeCredits,
        noAggregate: qs.noAggregate !== undefined,
        limitAggregation,
        dataSource: dataSource === AnalyticsDataSource.CUSTOMER_FEATURES ? AnalyticsDataSource.BILLING : dataSource,
        signal: controller.current.signal,
        channelId,
      };

      const splits = splitsParam(metricSplits, rows, cols);
      if (splits?.length > 0) {
        request.splits = splits;
      }

      const before = performance.now();

      try {
        const response = await executeQueryRequest(api, customer.id, request);
        const after = performance.now();
        const totalDurationMs = Math.round(after - before);
        if (response?.status === 200 && response?.data) {
          reportMixpanel.run(totalDurationMs, cloudProviders, response.data?.details);
          if (report.data.type === "custom") {
            updateReportStats(
              request.id,
              response.data.details?.serverDurationMs,
              response.data.details?.totalBytesProcessed,
              totalDurationMs,
              customer.presentationMode?.enabled
            );
          }

          setComparativeRequest(false);
          dispatchReportStates({ payload: { reportReady: false } });
          const metricOffset = rows.length + cols.length;
          const vals = getMetricOptions(metricOffset, scopedMetric, isCSP, !!count);
          const numMetrics = getMetricsCount(isCSP, scopedMetric, !!count);
          if (rows.length <= 1) {
            dispatchReportConfig({ payload: { includeSubtotals: false } });
          }
          reportData.current = new ReportData({
            data: response.data.rows,
            forecastRows: response.data.forecastRows,
            aggregator,
            rows,
            cols,
            rowOrder,
            colOrder,
            vals,
            transforms,
            features,
            numMetrics,
            comparative,
            includeSubtotals,
            forecastSettings,
          });

          if (reportData.current.getNumRecords() === 0 && trends.length > 0) {
            setReportError({ code: ErrorCode.EMPTY_DUE_TO_TREND });
          } else {
            setReportError(undefined);
          }

          const rowHeaders = reportData.current.getRowHeaders();
          const colHeaders = reportData.current.getColHeaders();
          const metricLabels = getBaseMetricLabels(dataSource, isCSP);
          if (scopedMetric === Metric.CALCULATED) {
            metricLabels.push(calculatedMetric?.data?.name ?? "");
          } else if (scopedMetric === Metric.EXTENDED) {
            metricLabels.push(extendedMetrics?.find((m) => m.key === extendedMetric)?.label ?? "");
          }
          const headerRow = [...rowHeaders, ...colHeaders, ...metricLabels];
          const headerRowLength = headerRow.length;

          if (!showForecast || !response.data.forecastRows) {
            reportDataWForecasts.current = undefined;
            dispatchReportStates({
              type: ReportStateKind.FINISHED_RUNNING,
              payload: {
                queryRunning: false,
                querySuccess: true,
              },
            });
            setSwitchedToCalcMetric(false);
            setSwitchedToExtendMetric(false);
            const withoutTrends = response?.data?.rows?.map((row) => row.slice(0, headerRowLength));
            const withHeaderRow = [headerRow, ...(withoutTrends ?? [])];
            setRawReportData(withHeaderRow);
            return;
          }

          const startIndex = reportData.current.rowStartIndex;
          const fullData = [...(response.data?.rows ?? []), ...response.data.forecastRows.slice(startIndex)];
          reportDataWForecasts.current = new ReportData({
            data: fullData,
            forecastRows: response.data.forecastRows,
            aggregator,
            rows,
            cols,
            rowOrder,
            colOrder,
            vals,
            transforms,
            features,
            numMetrics,
            forecastSettings,
          });
          dispatchReportStates({
            type: ReportStateKind.FINISHED_RUNNING,
            payload: {
              querySuccess: true,
            },
          });
          setSwitchedToCalcMetric(false);
          setSwitchedToExtendMetric(false);
          const withoutTrends = fullData?.map((row) => row.slice(0, headerRowLength));
          const withHeaderRow = [headerRow, ...(withoutTrends ?? [])];
          setRawReportData(withHeaderRow);
        }
      } catch (error: any) {
        if (error?.name === "CanceledError") {
          const after = performance.now();
          const totalDurationMs = Math.round(after - before);
          reportMixpanel.runCancel(totalDurationMs);
          dispatchReportStates({
            type: ReportStateKind.FINISHED_RUNNING,
            payload: {
              queryRunning: false,
              querySuccess: false,
            },
          });
          request.signal = undefined;
          cancelQuery(api, customer.id, report.snapshot.id, channelId);
          return;
        }
        if (error?.response?.status === 524) {
          setShowReportCacheBtn(true);
        }

        handleResponseError(
          error,
          (errorCode, details) => {
            setReportError({
              code: errorCode ?? ErrorCode.UNKNOWN,
              message: error.response?.data?.error?.message,
            });
            dispatchReportStates({
              type: ReportStateKind.FINISHED_RUNNING,
              payload: {
                querySuccess: false,
              },
            });
            setSwitchedToCalcMetric(false);
            setSwitchedToExtendMetric(false);
            setComparativeRequest(false);
            reportData.current = undefined;
            reportDataWForecasts.current = undefined;

            reportMixpanel.runError(errorCode, details);
          },
          [404, 413]
        );
      }

      dispatchReportStates({ payload: { queryRunning: false } });
    },
    [
      queryRunning,
      hasUnsavedChanges,
      isCachedReport,
      excludePartialData,
      includeCredits,
      extendedMetric,
      metric,
      timeRangeOption,
      dimensions,
      aggregator,
      timeInterval,
      cloudProviders,
      attributions,
      calculatedMetric?.data,
      attributionGroups,
      report.snapshot.id,
      report.data.type,
      metricFilters,
      currency,
      trends,
      showForecast,
      isEntitledAnalyticsForecasts,
      forecastSettings,
      comparative,
      qs.noAggregate,
      limitAggregation,
      dataSource,
      metricSplits,
      api,
      customer.id,
      customer.presentationMode?.enabled,
      reportMixpanel,
      isCSP,
      reportData,
      rowOrder,
      colOrder,
      transforms,
      features,
      includeSubtotals,
      reportDataWForecasts,
      setRawReportData,
      dispatchReportConfig,
      extendedMetrics,
      createRunReportChannel,
    ]
  );

  const handleCancelMetricFilters = (item?: MetadataOption) => {
    if (item) {
      handleDelete([item]);
    }
    setMetricFiltersDialogOpen(false);
    setSelected(undefined);
  };

  // Load report config to state once
  useEffect(() => {
    (async () => {
      if (!dimensions || !!initialConfig) {
        return;
      }
      reportMixpanel.open();
      metricSplitsInit(report.data.config?.splits ?? [], dimensions);
      dispatchReportStates({ payload: { mounted: true } });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions]);

  // track changes in report config, used for "run report" alert banner
  useEffect(() => {
    if (mounted) {
      dispatchReportStates({ type: ReportStateKind.REPORT_CHANGED });
    } else {
      dispatchReportStates({ payload: { querySuccess: false } });
    }
  }, [mounted, metricSplits, timeInterval, timeRangeOption, forecastSettings]);

  useEffect(() => {
    // if the query is running and the report changed, we show the run report alert banner
    if (!queryRunning && reportStates.reportChangedWhileRunning) {
      dispatchReportStates({ type: ReportStateKind.SHOW_RUN_REPORT_ALERT });
    }
  }, [queryRunning, reportStates.reportChangedWhileRunning]);

  useEffect(() => {
    setShowRunReportAlert(!reportError && (reportStates.reportChanged || reportStates.reportChangedWhileRunning));
  }, [reportStates.reportChanged, reportStates.reportChangedWhileRunning, reportError]);

  // Set as initialized once dimensions and calculated metric are ready
  useEffect(() => {
    if (!isInit && dimensions && (!report.data.config?.calculatedMetric || calculatedMetric)) {
      dispatchReportStates({ payload: { isInit: true } });
    }
  }, [isInit, dimensions, report.data.config?.calculatedMetric, calculatedMetric]);

  const renderReport = useCallback(async () => {
    if (reportData.current) {
      const metricOffset = reportData.current.rows?.length + reportData.current.cols.length;
      const vals = getMetricOptions(metricOffset, metric, isCSP, aggregator === Aggregator.COUNT);
      await reportData.current.update(aggregator, vals, features, comparative ?? undefined);
      if (reportData.current?.getNumRecords() === 0 && trends.length > 0) {
        setReportError({ code: ErrorCode.EMPTY_DUE_TO_TREND });
      } else {
        setReportError(undefined);
      }
      dispatchReportStates({ payload: { reportReady: true } });
    }
  }, [aggregator, comparative, features, isCSP, metric, reportData, trends.length]);

  const saveButtonDisabled = useMemo(
    () =>
      !mounted ||
      report.data.type === AnalyticsResourceType.PRESET ||
      !isCurrentUserEditor ||
      (!hasUnsavedChanges && !!name),
    [hasUnsavedChanges, isCurrentUserEditor, mounted, name, report.data.type]
  );

  useEffect(() => {
    if (!reportData.current) {
      return;
    }
    if (!IsValidAggregator(aggregator, metric)) {
      dispatchReportConfig({ payload: { aggregator: Aggregator.TOTAL } });
      return;
    }

    if (comparativeRequest || switchedToCalcMetric || switchedToExtendMetric) {
      return;
    }

    if (showForecast) {
      return;
    }
    dispatchReportStates({ payload: { reportReady: false } });
    renderReport();
  }, [
    showForecast,
    metric,
    aggregator,
    trends,
    features,
    calculatedMetric,
    switchedToCalcMetric,
    comparative,
    comparativeRequest,
    isCSP,
    renderReport,
    switchedToExtendMetric,
    dispatchReportConfig,
    reportData,
  ]);

  useEffect(() => {
    if (!reportData.current) {
      return;
    }
    dispatchReportStates({ payload: { reportReady: false } });
    const dataProp = showForecast && reportDataWForecasts.current ? reportDataWForecasts.current : reportData.current;
    if (dataProp) {
      dataProp.setSort(rowOrder, colOrder, colKeySort);
      const t = setTimeout(() => {
        dispatchReportStates({ payload: { reportReady: true } });
      }, 0);
      return () => {
        clearTimeout(t);
      };
    }
  }, [rowOrder, colOrder, showForecast, colKeySort, reportData, reportDataWForecasts]);

  const onSaveCallback = useCallback(async () => {
    if (isCurrentUserEditor && name) {
      await handleSave();
      goBackToReports();
      return true;
    } else {
      setHotKeysDisabled();
      setOpenNameDialog(true);
      return false;
    }
  }, [goBackToReports, handleSave, isCurrentUserEditor, name, setHotKeysDisabled]);

  useEffect(() => {
    if (
      (hasUnsavedChanges || createTemplateData.hasUnsavedChanges) &&
      report.data.type !== AnalyticsResourceType.PRESET &&
      !isDeleting
    ) {
      activatePendingPrompt({
        onConfirmCallback: () => {},
        onSaveCallback: createTemplateData.isCreateTemplateOpen || reportTemplateId ? undefined : onSaveCallback,
      });
    } else {
      clearPendingPrompt();
    }
  }, [
    activatePendingPrompt,
    clearPendingPrompt,
    createTemplateData.hasUnsavedChanges,
    createTemplateData.isCreateTemplateOpen,
    hasUnsavedChanges,
    isDeleting,
    onSaveCallback,
    report.data.type,
    reportTemplateId,
  ]);

  // Update the time interval setting according to changes in columns
  useUpdateEffect(() => {
    const cols = dimensionsCols?.map((md) => md.data.key);
    const newTimeInterval = timeIntervalOptions.find(
      (option) => isEqual(cols, option.visible) || isEqual(cols, option.available)
    );
    if (newTimeInterval) {
      if (newTimeInterval.value !== timeInterval) {
        dispatchReportConfig({ payload: { timeInterval: newTimeInterval.value } });
      }
    }
  }, [dimensionsCols, timeInterval]);

  // Resets countFieldMissing state
  useEffect(() => {
    if (aggregator === Aggregator.COUNT) {
      setCountFieldMissing((prevCountFieldMissing: boolean) => {
        if (prevCountFieldMissing) {
          const countFieldExists =
            !!dimensions && dimensions?.some((md) => md._position === Positions.COUNT && md._visible);
          if (countFieldExists) {
            return false;
          }
        }
        return prevCountFieldMissing;
      });
    }
  }, [dimensions, aggregator]);

  const changeReportFilter = useCallback(
    (filterId: string, type: string, value: string[] | string, inverse?: boolean) => {
      handleReportFilterChange(filterId, type, value, inverse);
    },
    [handleReportFilterChange]
  );

  const openFilterDialog = (filterChip: MetadataOption, open: boolean) => {
    if (filterChip.data.type === Metadata.METRIC) {
      setMetricFiltersDialogOpen(true);
    } else if (filterChip.data.type === Metadata.LIMITS) {
      setLimitsFilterDialogOpen(true);
    } else {
      setFilterDialogOpen(open);
    }
  };

  const handleAddDimension = useCallback(
    (
      dimensions: MetadataOption[],
      simulateClick: boolean = false,
      fromList: boolean = false,
      openDialog: boolean = true,
      filterExistingChip: boolean = false
    ) =>
      (event?: any) => {
        const lastDimension = dimensions.at(-1);
        if (!lastDimension) {
          return;
        }

        if (lastDimension.selectedValue) {
          changeReportFilter(lastDimension.id, "values", [
            lastDimension.selectedValue,
            ...(lastDimension._filter ?? []),
          ]);
        }
        if (event?.metaKey || simulateClick) {
          handleDimensionSelect(dimensions, fromList, filterExistingChip);
          if (lastDimension.selectedValue) {
            return;
          }

          if (
            fromList &&
            (lastDimension._position === Positions.UNUSED ||
              lastDimension.data.type === Metadata.ATTRIBUTION ||
              filterExistingChip)
          ) {
            setSelected(lastDimension);
            openFilterDialog(lastDimension, openDialog);
          }
        } else {
          setSelected(lastDimension);
          openFilterDialog(lastDimension, true);
        }
      },
    [changeReportFilter, handleDimensionSelect]
  );

  const handleChangeLabels = useCallback(
    async (values: KeyTypeValues[], position: Positions) => {
      const selectedDimension = await handleDimensionLabelsChange(values, undefined, position);
      if (selectedDimension && position === Positions.UNUSED) {
        setSelected(selectedDimension);
        openFilterDialog(selectedDimension, true);
      }
    },
    [handleDimensionLabelsChange]
  );

  const handleChangeFilter = useCallback(
    (type: string, value: string[] | string, inverse?: boolean) => {
      changeReportFilter(selected?.id ?? "", type, value, inverse);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected?.id]
  );

  const handleChangeToTreemap = useCallback(() => {
    dispatchReportConfig({
      payload: {
        aggregator: Aggregator.TOTAL,
        features: [],
        renderer: Renderer.TREEMAP,
        comparative: ComparativeFeature.NONE,
      },
    });
    handleTreemapChange();
    handleClose();
    reportMixpanel.viewChanged();
    touched.add("rendererSelect");
    reRender();
  }, [reRender, touched, handleClose, handleTreemapChange, dispatchReportConfig, reportMixpanel]);

  const handleCalculatedMetric = useCallback(() => {
    dispatchReportConfig({ payload: { metric: Metric.CALCULATED, calculatedMetric: selectedCalcMetric } });
    setSwitchedToCalcMetric(true);
    updateMetricFilters([]);
    reportMixpanel.calculatedMetricSelect(selectedCalcMetric?.snapshot.id);
    handleClose();
  }, [selectedCalcMetric, updateMetricFilters, handleClose, dispatchReportConfig, reportMixpanel]);

  useUpdateEffect(() => {
    if (calculatedMetric) {
      dispatchReportConfig({ payload: { aggregator: Aggregator.TOTAL, features: [], metric: Metric.CALCULATED } });

      handleAttributionMdSelect();
      const usedAttributions = uniq(calculatedMetric?.data?.variables?.map((v) => v.attribution.id) ?? []);
      changeReportFilter(`${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`, "values", usedAttributions);
    }
  }, [calculatedMetric]);

  useUpdateEffect(() => {
    handleAggregatorChange(aggregator);
  }, [aggregator]);

  useUpdateEffect(() => {
    if (switchedToCalcMetric) {
      dispatchReportStates({ payload: { reportReady: false } });
      if (!reportStates.finishedFirstQueryRun) {
        return;
      }
      handleRun();
    }
  }, [dimensions]);

  useUpdateEffect(() => {
    dispatchReportStates({ payload: { reportReady: false } });
    if (!reportStates.finishedFirstQueryRun) {
      return;
    }
    handleRun();
  }, [includeCredits, excludePartialData]);

  useUpdateEffect(() => {
    if (comparative && isComparative(comparative) && timeDimensionsReady) {
      dispatchReportStates({ payload: { reportReady: false } });
      setTimeDimensionsReady(false);
      if (!reportStates.finishedFirstQueryRun) {
        return;
      }
      handleRun();
    }
  }, [comparative, timeDimensionsReady]);

  useUpdateEffect(() => {
    if (!switchedToCalcMetric) {
      setSelectedCalcMetric(null);
    }
  }, [switchedToCalcMetric]);

  const sortComparativeDimensions = () => {
    handleSortComparativeDimensions();
    setTimeDimensionsReady(true);
  };

  const updateSkuUnitProjectDimensions = (metricVal: Metric) => {
    const skuDimension = dimensions?.find((m) => m.id === `${Metadata.FIXED}:${FixedFilters.SKU}`);
    const unitDimension = dimensions?.find((m) => m.id === `${Metadata.FIXED}:${FixedFilters.UNIT}`);
    const projectDimension = dimensions?.find((m) => m.id === `${Metadata.FIXED}:${FixedFilters.PROJECT}`);

    const combinedDimensions: MetadataOption[] = [];
    skuDimension && combinedDimensions.push(skuDimension);
    unitDimension && combinedDimensions.push(unitDimension);
    const combinedDimensionsExist = combinedDimensions.length > 0;

    if (metricVal === Metric.USAGE) {
      projectDimension && handleDelete([projectDimension]);
      combinedDimensionsExist && handleAddDimension(combinedDimensions, true, false, true, true)();
      dispatchReportConfig({ payload: { renderer: Renderer.ROW_HEATMAP } });

      touched.add("rendererSelect");
    } else if (metricVal !== Metric.EXTENDED) {
      combinedDimensionsExist && handleDelete(combinedDimensions);
      projectDimension && handleDimensionSelect([projectDimension], true, true);
      dispatchReportConfig({ payload: { renderer: Renderer.STACKED_COLUMN_CHART } });
    }
  };

  const handleMetricSelect = (metricVal: Metric, value: MetricWSnap | string) => {
    if (metricVal === Metric.CALCULATED) {
      setSelectedCalcMetric(value as MetricWSnap);
      setCalcMetricsDialogOpen(true);
      return;
    } else if (metricVal === Metric.EXTENDED) {
      dispatchReportConfig({ payload: { metric: metricVal, extendedMetric: value as string } });
      setSwitchedToExtendMetric(true);
      reportStates.finishedFirstQueryRun && handleRun({ metric: metricVal, extendedMetric: value as string });
    } else {
      dispatchReportConfig({ payload: { extendedMetric: "" } });
    }

    // Simulate click on "Unit" chip when usage is selected
    if (!reportStates.finishedFirstQueryRun && dimensions) {
      updateSkuUnitProjectDimensions(metricVal);
    }
    if (calculatedMetric) {
      handleMetricChange();
      updateMetricFilters([]);
    }
    reportMixpanel.metricSelect(metricVal, value);

    dispatchReportStates({ payload: { reportReady: false } });
    dispatchReportConfig({ payload: { metric: metricVal, calculatedMetric: null } });
  };

  const handleAggregatorSelect = (newAggregator: Aggregator) => {
    if (newAggregator === Aggregator.COUNT) {
      reportData.current = undefined;
      dispatchReportStates({ payload: { reportReady: false } });
      setCountFieldMissing(false);
    } else if (aggregator === Aggregator.COUNT) {
      setCountFieldMissing(false);
    }

    dispatchReportConfig({ payload: { aggregator: newAggregator } });
  };

  const handleResetLimits = () => {
    const mdWasReset = resetLimit();
    if (mdWasReset) {
      showSharedSnackbar({
        message: reportText.LIMITS_FILTER_RESET,
        variant: "info",
        autoHideDuration: 5000,
      });
    }
  };

  const location = useLocation();

  const runOnOpen = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("run-on-open") === "true";
  }, [location]);

  const [hasAlreadyRun, setHasAlreadyRun] = useState(false);

  useEffect(() => {
    // automatically run report
    if (
      !hasAlreadyRun &&
      reportStates.isInit &&
      reportStates.mounted &&
      report.data.config &&
      (!report.data.draft || runOnOpen) &&
      !reportStates.firstRender &&
      dashboardsContextLoaded &&
      !isReportCacheLoading
    ) {
      if (!queryRunning) {
        handleRun();
      }
      setHasAlreadyRun(true);
    }
  }, [
    reportStates,
    hasAlreadyRun,
    handleRun,
    queryRunning,
    isCachedReport,
    saveButtonDisabled,
    reportConfig,
    report.data.config,
    report.data.draft,
    runOnOpen,
    dashboardsContextLoaded,
    isReportCacheLoading,
  ]);

  useUpdateEffect(() => {
    dispatchReportStates({ payload: { reportReady: false } });
    if (!reportStates.finishedFirstQueryRun) {
      return;
    }
    // run only on changes of metricFilters. not of calculatedMetric selection/changes
    if (!switchedToCalcMetric) {
      handleRun();
    }
  }, [metricFilters]);

  useUpdateEffect(() => {
    dispatchReportStates({ payload: { reportReady: false } });
    if (!reportStates.finishedFirstQueryRun) {
      return;
    }
    handleRun();
  }, [currency]);

  useUpdateEffect(() => {
    if (!reportStates.finishedFirstQueryRun) {
      return;
    }
    dispatchReportStates({ payload: { reportReady: false } });
    handleRun();
  }, [features]);

  useUpdateEffect(() => {
    if (hotKeysEnabled && runShortcutKey) {
      dispatchReportStates({ payload: { reportReady: false } });

      handleRun();
    }
  }, [runShortcutKey]);

  useUpdateEffect(() => {
    if (hotKeysEnabled && (tableViewShortcutKey || heatmapViewShortcutKey) && renderer !== prevView) {
      const desiredView = tableViewShortcutKey ? Renderer.TABLE : Renderer.HEATMAP;
      const newView = renderer !== desiredView ? desiredView : prevView;
      reportMixpanel.toggleView(newView);
      setPrevView(renderer);
      dispatchReportConfig({ payload: { renderer: newView } });

      touched.add("rendererSelect");
    }
  }, [tableViewShortcutKey, heatmapViewShortcutKey]);

  useUpdateEffect(() => {
    if (!touched.has("rowOrder")) {
      dispatchReportConfig({ payload: { rowOrder: getReportRowSort(renderer) } });
    }
  }, [renderer]);

  const renderSheetsDialog = useCallback(() => {
    if (!googleDialogOpen) {
      return null;
    }

    const dataProp = showForecast && reportDataWForecasts.current ? reportDataWForecasts.current : reportData.current;
    if (!dataProp) {
      return null;
    }
    return (
      <GoogleSheetsDialog
        data={dataProp}
        isDialog={true}
        isDialogOpen={true}
        onClose={handleClose}
        aggregator={aggregator}
        metric={metric}
      />
    );
  }, [googleDialogOpen, showForecast, reportDataWForecasts, reportData, handleClose, aggregator, metric]);

  const baseFormatter = useFormatter({
    aggregator,
    currency: currency || CurrencyCodes.USD,
    metric,
    options: {
      ...(extendedMetricMode && { extendedMetricMode }),
    },
  });

  const calculatedMetricFormatter = useMetricFormatter(
    aggregator,
    currency || CurrencyCodes.USD,
    calculatedMetric?.data || null
  );

  // these configurations will be removed when changing to treemap
  const shouldTreemapUpdateConfig = useMemo(
    () =>
      !!(
        aggregator !== Aggregator.TOTAL ||
        showForecast ||
        !!trends.length ||
        !!dimensionsCols?.length ||
        (comparative && isComparative(comparative))
      ),
    [aggregator, showForecast, trends, dimensionsCols, comparative]
  );

  const handleRightPanelClose = useCallback(() => {
    setRightPanelOpen(false);
  }, []);

  const cachedReportClicked = useCallback(async () => {
    setIsReportCachedBtnLoading(true);
    await handleCacheReport();
    showSharedSnackbar({
      message: reportTxt.REPORT_CACHE_SNACKBAR_TEXT,
      variant: "info",
      autoHideDuration: 7000,
    });
  }, [handleCacheReport, showSharedSnackbar]);

  const displayedPlot = useMemo(
    () => (
      <PlotRenderer
        cacheReport={cachedReportClicked}
        cancelReportRun={() => {
          controller.current.abort();
        }}
        colKeySort={colKeySort}
        countFieldMissing={countFieldMissing}
        disableCacheBtn={hasUnsavedChanges}
        forceRender={forceRender}
        formatter={calculatedMetric ? calculatedMetricFormatter : baseFormatter}
        highchartsTitle={report.data.name}
        isReportCachedBtnLoading={isReportCachedBtnLoading}
        isTreemapExact={isTreemapExact}
        leftDrawerTransitioning={leftDrawerTransitioning}
        renderKey={renderKey}
        reportError={reportError}
        reportReady={reportReady}
        reportQueryRunning={queryRunning}
        setColKeySort={setColKeySort}
        setPopoverAnchorEl={setPopoverAnchorEl}
        showForecast={showForecast}
        showReportCacheBtn={showReportCacheBtn}
      />
    ),
    [
      cachedReportClicked,
      colKeySort,
      countFieldMissing,
      hasUnsavedChanges,
      forceRender,
      calculatedMetric,
      calculatedMetricFormatter,
      baseFormatter,
      report.data.name,
      isReportCachedBtnLoading,
      isTreemapExact,
      leftDrawerTransitioning,
      renderKey,
      reportError,
      reportReady,
      queryRunning,
      showForecast,
      showReportCacheBtn,
    ]
  );

  // Hotkeys disabled when popover/dialogs open
  useEffect(() => {
    if (
      googleDialogOpen ||
      filterDialogOpen ||
      openNameDialog ||
      popoverAnchorEl?.widget ||
      popoverAnchorEl?.mdCol ||
      popoverAnchorEl?.mdRow ||
      popoverAnchorEl?.mdUnused ||
      popoverAnchorEl?.mdCount ||
      saveAsComponentVisible ||
      metricSplitModalData ||
      createTemplateData.isCreateTemplateOpen
    ) {
      setHotKeysDisabled();
    } else {
      setHotKeysEnabled();
    }
  }, [
    filterDialogOpen,
    googleDialogOpen,
    popoverAnchorEl?.widget,
    popoverAnchorEl?.mdCol,
    popoverAnchorEl?.mdRow,
    popoverAnchorEl?.mdUnused,
    popoverAnchorEl?.mdCount,
    saveAsComponentVisible,
    setHotKeysDisabled,
    setHotKeysEnabled,
    metricSplitModalData,
    openNameDialog,
    createTemplateData.isCreateTemplateOpen,
  ]);

  const prevQuerySuccess = usePrevious(querySuccess);

  const numOfGroups = useMemo(
    () => dimensions?.filter((item) => item._visible && item._position === Positions.ROW).length || 0,
    [dimensions]
  );

  useEffect(() => {
    if (!report.data.draft || touched.has("rendererSelect") || report.data.config !== null) {
      return;
    }

    if (!reportStates.finishedFirstQueryRun) {
      if (numOfGroups > 1 && renderer === Renderer.STACKED_COLUMN_CHART) {
        dispatchReportConfig({ payload: { renderer: Renderer.TABLE } });
      } else if (numOfGroups < 2 && renderer === Renderer.TABLE) {
        dispatchReportConfig({ payload: { renderer: Renderer.STACKED_COLUMN_CHART } });
      }
    } else {
      const wasRerun = reportStates.finishedFirstQueryRun && !prevQuerySuccess && querySuccess;

      if (wasRerun && numOfGroups > 1) {
        dispatchReportConfig({ payload: { renderer: Renderer.TABLE } });
      }
    }
  }, [
    numOfGroups,
    renderer,
    reportStates.finishedFirstQueryRun,
    querySuccess,
    prevQuerySuccess,
    touched,
    dispatchReportConfig,
    report.data.draft,
    report.data.config,
  ]);

  const handleComparativeChange = (value) => {
    if (comparative === ComparativeFeature.NONE) {
      setComparativeRequest(true);
      setComparativeDialogOpen(true);
    }
    dispatchReportConfig({ payload: { comparative: value } });
  };

  const resetReport = useCallback(
    async (newConfig: ReportConfig) => {
      const deletedDimensions = dimensions?.filter((d) => d._visible && d.data.type !== Metadata.DATETIME);
      deletedDimensions?.length && handleDelete(deletedDimensions);
      await handleResetReport(newConfig);
      reportData.current = undefined;
      reportDataWForecasts.current = undefined;
      dispatchReportStates({ type: ReportStateKind.REPORT_RESET });
      setReportError(undefined);
    },
    [dimensions, handleDelete, handleResetReport, reportData, reportDataWForecasts]
  );

  const handleRefreshReportCache = useCallback(async () => {
    try {
      setIsRefreshCacheBtnLoading(true);
      const response = await refreshWidget(api, customer.id, report.snapshot.id);
      if (response.status === 200) {
        setForceRender(true);
      }
    } catch (error: any) {
      if (error?.response?.status === 524) {
        return;
      } else {
        consoleErrorWithSentry(error);
        setForceRender(false);
        error.response.status = 500;
      }
    } finally {
      setForceRender(false);
      setIsRefreshCacheBtnLoading(false);
    }
  }, [api, customer.id, report.snapshot.id]);

  const renderReportActionButton = useCallback(() => {
    if (isCachedReport && !hasUnsavedChanges) {
      const disableRefreshCacheButton =
        !saveButtonDisabled ||
        userCachedReportWidget?.state === WidgetState.Processing ||
        isReportCacheRecentlyRefreshed;
      let refreshCacheTooltip = reportTxt.REPORT_CACHE_REFRESH_BTN_TOOLTIP;

      if (isRefreshCacheBtnLoading || userCachedReportWidget?.state === WidgetState.Processing) {
        refreshCacheTooltip = reportTxt.REPORT_CACHE_REFRESH_BTN_PROCESSING_TOOLTIP;
      } else if (isReportCacheRecentlyRefreshed) {
        refreshCacheTooltip = reportTxt.REPORT_CACHE_REFRESH_BTN_RECENTLY_UPDATED_TOOLTIP;
      }

      return (
        <Tooltip arrow title={refreshCacheTooltip}>
          <Box sx={{ flex: 1 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={disableRefreshCacheButton}
              loading={isRefreshCacheBtnLoading}
              onClick={handleRefreshReportCache}
              sx={{
                borderRadius: 1,
                width: "100%",
                justifyContent: "space-between",
              }}
              mixpanelEventId="analytics.report.refresh"
            >
              {reportTxt.REFRESH_CACHE}
            </LoadingButton>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Box sx={{ flex: 1 }}>
        <MixpanelButton
          variant="contained"
          color="primary"
          onClick={() => handleRun()}
          disabled={queryRunning}
          data-cy="run-report"
          sx={{
            borderRadius: 1,
            width: "100%",
            justifyContent: "space-between",
          }}
          mixpanelEventId="analytics.report.run"
        >
          <span>{reportText.RUN_REPORT}</span>
          <span>{reportTxt.RUN_REPORT_SHORTCUT}</span>
        </MixpanelButton>
      </Box>
    );
  }, [
    userCachedReportWidget?.state,
    handleRefreshReportCache,
    handleRun,
    hasUnsavedChanges,
    isCachedReport,
    isRefreshCacheBtnLoading,
    isReportCacheRecentlyRefreshed,
    queryRunning,
    saveButtonDisabled,
  ]);

  if (!customer || !dimensions) {
    return <CircularProgressLoader />;
  }

  const handleConfirmComparative = () => {
    dispatchReportConfig({ type: ReportConfigKind.ON_COMPARATIVE_MODE });
    sortComparativeDimensions();
    reportMixpanel.comparativeOn();
    handleClose();
  };

  const chartHeight = `calc(100vh - ${reportHeight}px)`;

  return (
    <Box sx={{ margin: "-8px -16px 0" }}>
      <DoitConsoleTitle pageName="Reports" pageLevel1={name || reportText.NEW_REPORT} />
      <ReportHeader
        removeReportFromCache={removeReportFromCache}
        handleRevert={handleRevertReport}
        isCachedReport={isCachedReport}
        popoverAnchorEl={popoverAnchorEl}
        reportCacheRefreshedAt={rawCacheReport?.timeRefreshed.toDate()}
        reportError={reportError?.code}
        saveButtonDisabled={saveButtonDisabled}
        setGoogleDialogOpen={setGoogleDialogOpen}
        setIsDeleting={setIsDeleting}
        setPopoverAnchorEl={setPopoverAnchorEl}
        setRightPanelOpen={setRightPanelOpen}
        showRemoveFromCache={isCachedReport && reportCacheType === ReportCacheType.UserCached}
        wasRun={reportStates.finishedFirstQueryRun}
      />
      {smDown ? (
        <Paper
          variant="outlined"
          ref={reportHeightRef}
          sx={{
            overflowX: "scroll",
            height: chartHeight,
          }}
        >
          <Box sx={{ minWidth: 768, height: "100%" }}>{displayedPlot}</Box>
        </Paper>
      ) : (
        <>
          <DndProvider backend={HTML5Backend}>
            <Grid container ref={reportHeightRef} height={chartHeight} display="flex" direction="row" wrap="nowrap">
              {/* LEFT */}
              <Grid item display="flex">
                <Box
                  sx={{
                    width: reportLeftDrawerOpen ? REPORT_LEFT_PANEL_WIDTH : REPORT_LEFT_PANEL_WIDTH_CLOSED,
                    transition: "width 0.3s",
                    borderRight: "1px solid",
                    borderRightColor: "general.divider",
                  }}
                  onTransitionEnd={(transitionEvent) => {
                    onTransitionEnd(transitionEvent);
                  }}
                >
                  {reportLeftDrawerOpen && (
                    <Stack height="100%" direction="column">
                      <Stack
                        sx={{
                          overflowY: "auto",
                          height: `calc(100% - ${REPORT_LEFT_PANEL_BOTTOM_HEIGHT}px)`,
                          px: 3,
                          maxWidth: "100%",
                        }}
                      >
                        <DraggableGroupWrapper
                          resetReport={resetReport}
                          handleMetricSelect={handleMetricSelect}
                          handleAggregatorSelect={handleAggregatorSelect}
                          isCSP={isCSP}
                          queryRunning={queryRunning}
                          handleResetLimits={handleResetLimits}
                          handleDelete={handleDelete}
                          handleAddDimension={handleAddDimension}
                          handleChangeLabels={handleChangeLabels}
                          handleFilterOperation={handleFilterPopoverOpened}
                          popoverAnchorEl={popoverAnchorEl}
                          setPopoverAnchorEl={setPopoverAnchorEl}
                          touched={touched}
                          setColKeySort={setColKeySort}
                          colSortDisabled={colSortDisabled}
                        />
                      </Stack>
                      <Stack
                        alignItems="center"
                        direction="row"
                        flex={1}
                        sx={{
                          borderTop: "1px solid",
                          borderTopColor: "general.divider",
                          px: 3,
                        }}
                      >
                        {renderReportActionButton()}
                        <IconButton
                          sx={{ ml: 1 }}
                          onClick={() => {
                            setReportLeftDrawerOpen(false);
                          }}
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Stack>
                    </Stack>
                  )}
                  {!reportLeftDrawerOpen && (
                    <Box display="flex" alignItems="flex-end" height="100%">
                      <IconButton
                        onClick={() => {
                          setReportLeftDrawerOpen(true);
                          setLeftDrawerTransitioning(true);
                        }}
                        sx={{
                          ml: 2,
                          mb: 2.5,
                          p: 0,
                        }}
                      >
                        <ArrowRight />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* MIDDLE */}
              <Grid
                container
                item
                alignContent="flex-start"
                flex="1"
                width={`calc(100% - ${
                  REPORT_LEFT_PANEL_WIDTH + 8 + (createTemplateData.isCreateTemplateOpen ? 398 : 0)
                }px)`}
              >
                {/* COLUMNS */}
                <Grid item xs={12}>
                  {renderSheetsDialog()}
                </Grid>
                <Grid item xs={12} sx={{ pt: 2.625, pb: 3.5, pl: 3, pr: 3.5 }} className={classes.middlePanelHeight}>
                  <Box className={classes.middlePanelHeight}>
                    <Stack direction="column" className={classes.middlePanelHeight}>
                      <Grid item className={classes.topPanelConfig} xs={12}>
                        <Grid className={classes.topPanelConfigOption} minWidth={195}>
                          <ReportViewSelect
                            shouldTreemapUpdateConfig={shouldTreemapUpdateConfig}
                            setTreemapDialogOpen={setTreemapDialogOpen}
                            disableTreemap={false}
                            touched={touched}
                          />
                        </Grid>
                        <Grid className={classes.topPanelConfigOption} data-testid="comparative-data">
                          <TextField
                            size="small"
                            label={reportText.COMPARATIVE_LABEL}
                            disabled={report.data.type === "preset"}
                            value={comparative}
                            onChange={(event) => {
                              handleComparativeChange(event.target.value);
                            }}
                            variant="outlined"
                            margin="dense"
                            select
                            fullWidth
                            SelectProps={{
                              classes: {
                                root: classes.selectOutlined,
                              },
                              MenuProps,
                            }}
                          >
                            {Object.values(comparativeValues).map((compOption) => (
                              <MenuItem
                                key={compOption.value}
                                value={compOption.value}
                                dense
                                disabled={"renderer" in compOption && !compOption.renderer.includes(renderer)}
                              >
                                {compOption.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      {/* PLOT */}
                      <Grid data-testid="plot" item xs={12} sx={{ overflow: "auto", pl: "4px", pr: "4px" }}>
                        {showRunReportAlert && (
                          <Info
                            text={reportText.RUN_REPORT_MESSAGE}
                            setHidden={() => {
                              dispatchReportStates({ type: ReportStateKind.HIDE_RUN_REPORT_ALERT });
                            }}
                            actions={
                              <MixpanelButton
                                key={reportText.RUN_REPORT}
                                variant="contained"
                                onClick={() => void handleRun()}
                                disabled={queryRunning}
                                mixpanelEventId="analytics.report.click-run"
                              >
                                {reportText.RUN_REPORT}
                              </MixpanelButton>
                            }
                          />
                        )}
                        {showLimitAlert && (
                          <Info setHidden={setHideLimitAlert} text={cloudAnalyticsText.LIMITS.ALERT_TEXT} />
                        )}
                        <Box sx={{ minWidth: 768, height: "100%" }}>{displayedPlot !== null && displayedPlot}</Box>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>

              {isDoitEmployee && createTemplateData.isCreateTemplateOpen && (
                <Grid item sx={{ pl: 1 }}>
                  <TemplateCreate />
                </Grid>
              )}

              {/* RIGHT */}
              <ReportRightMenu
                metadata={dimensions}
                open={rightPanelOpen}
                handleClose={handleRightPanelClose}
                extendedMetric={extendedMetrics?.find((m) => m.key === extendedMetric)?.label ?? ""}
                calculatedMetricFormatter={calculatedMetricFormatter}
              />
            </Grid>
          </DndProvider>

          {metricSplitModalData && <SplitCostModal />}

          {selected && (
            <FilterDialog
              open={filterDialogOpen}
              onClose={handleClose}
              selected={selected}
              handleCancelFilter={handleDelete}
              onSave={handleChangeFilter}
              cloudProviders={selected?.id !== `${Metadata.FIXED}:${FixedFilters.CLOUD}` ? cloudProviders : null}
            />
          )}
        </>
      )}
      <SimpleDialog
        open={comparativeDialogOpen}
        onConfirm={handleConfirmComparative}
        title={comparativeDialogText.COMPARATIVE_DIALOG_HEADER}
        onCancel={() => {
          dispatchReportConfig({ payload: { comparative: ComparativeFeature.NONE } });

          setComparativeRequest(false);
          handleClose();
        }}
        confirmButtonText={comparativeDialogText.DIALOG_ACTION_BUTTON}
      >
        <DialogContent>
          <DialogContentText>
            {comparativeDialogText.COMPARATIVE_DIALOG_BODY}
            <br />
            <br />
            {comparativeDialogText.COMPARATIVE_DIALOG_BULLETS.map((t, i) => (
              <Fragment key={i}>
                &nbsp;{t}
                <br />
              </Fragment>
            ))}
          </DialogContentText>
        </DialogContent>
      </SimpleDialog>
      <TreemapConfirmDialog
        open={treemapDialogOpen}
        onClose={handleClose}
        onConfirm={handleChangeToTreemap}
        cols={dimensionsCols?.map((md) => `"${md.data.label}"`) ?? []}
      />
      <SimpleDialog
        title={metricText.METRIC_DIALOG_TITLE(selectedCalcMetric?.data.name)}
        open={calcMetricsDialogOpen}
        onCancel={() => {
          setSelectedCalcMetric(null);
          handleClose();
        }}
        onConfirm={handleCalculatedMetric}
        confirmButtonText={metricText.METRIC_DIALOG_CONFIRM}
      >
        <DialogContent>
          <DialogContentText>
            {metricText.METRIC_DIALOG_CONTENT}
            <List dense>
              <ListItem>
                <ListItemText primary={metricText.METRIC_DIALOG_RESTRICTIONS} />
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
      </SimpleDialog>
      <MetricFiltersDialog
        calculatedMetric={calculatedMetric}
        extendedMetric={extendedMetrics?.find((m) => m.key === extendedMetric)?.label}
        isCSP={isCSP}
        metricFilter={getMetricFilter()}
        onCancel={handleCancelMetricFilters}
        onConfirm={handleMetricFilters}
        open={metricFiltersDialogOpen}
        selected={selected}
      />
      <LimitsFilterDialog
        origLimits={dimensions?.filter((m) => m._visible && m._position === Positions.ROW)}
        isCSP={isCSP}
        calculatedMetric={calculatedMetric}
        extendedMetric={extendedMetrics?.find((m) => m.key === extendedMetric)?.label}
        metric={metric}
        onCancel={handleCancelLimits}
        onConfirm={onLimitsFilterConfirm}
        open={limitsFilterDialogOpen}
        selected={selected}
        initialAggregation={limitAggregation}
      />
      {saveAsComponentVisible && (
        <SaveAsComponent
          saveAsTitle={reportText.SAVE_NEW}
          onSaveAs={(name) =>
            handleSaveAsComponentSave(name, () => {
              setSaveAsComponentVisible(false);
            })
          }
          textFieldProps={{
            helperText: reportText.SAVE_AS_HELPER_TEXT,
            label: reportText.SAVE_AS_LABEL,
          }}
          successMessage={reportText.SUCCESSFULLY_SAVED}
          disabled={false}
        />
      )}
      {openNameDialog && (
        <SaveDialog
          title={reportTxt.NAME_BEFORE_SAVING}
          open={openNameDialog}
          onClose={handleClose}
          onSave={(name) =>
            handleSaveName(name, () => {
              setOpenNameDialog(false);
            })
          }
          textFieldProps={{ label: reportTxt.REPORT_NAME }}
        />
      )}
    </Box>
  );
};

export default Report;
