import { type ReactNode, type SyntheticEvent, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { type SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useAuthContext } from "../../../Context/AuthContext";
import mixpanel from "../../../utils/mixpanel";
import { useInsights } from "../api";
import { useInsightsContext } from "../context";
import { NotFound } from "../NotFound/NotFound";
import {
  ALL_CATEGORIES,
  ALL_PROVIDERS,
  type Category,
  type Insight,
  type ProviderTag,
  type SavingsPeriod,
} from "../types";
import { isOptimized, sortByTotalPotentialDailySavings } from "../utils";
import { InsightsListRow } from "./InsightsListRow";
import { InsightsListRowUpsell } from "./InsightsListRowUpsell";

const VALUE_ASCENDING = "ascending";
const VALUE_DESCENDING = "descending";
const CREATE_CUSTOM_INSIGHT = "Create new insight";

function capitalizeProvider(provider: string): string {
  const capitalizationMap: Record<string, string> = {
    aws: "AWS",
    gcp: "GCP",
    azure: "Azure",
    snowflake: "Snowflake",
  };
  return capitalizationMap[provider] || "";
}

interface Filters {
  providers: ProviderTag[];
  categories: Category[];
  sources: string[];
}

interface FilterConfig {
  title: string;
  items: string[];
  filterKey: keyof Filters;
  getLabel: (item: string) => string;
  filterFunction: (insight: Insight, item: string) => boolean;
}

function FilterSection({
  config,
  filters,
  handleFilterChange,
  insights,
}: {
  config: FilterConfig;
  filters: Filters;
  handleFilterChange: (category: keyof Filters, value: string) => void;
  insights: Insight[] | undefined;
}) {
  const { title, items, filterKey, getLabel, filterFunction } = config;
  const currentFilterValues = filters[filterKey].map(String);
  return (
    <Box key={title} sx={{ mb: 3 }}>
      <Typography variant="subtitle2" mb={1}>
        {title}
      </Typography>
      <FormGroup>
        {items.map((item) => (
          <FormControlLabel
            key={item}
            control={
              <Checkbox
                size="small"
                checked={currentFilterValues.includes(item)}
                onChange={() => {
                  handleFilterChange(filterKey, item);
                }}
              />
            }
            label={
              <Typography variant="body2" component="span">
                {getLabel(item)}{" "}
                <Typography variant="body2" component="span" color="textSecondary">
                  ({insights?.filter((insight) => filterFunction(insight, item)).length ?? 0})
                </Typography>
              </Typography>
            }
            sx={{ mb: 1 }}
          />
        ))}
      </FormGroup>
    </Box>
  );
}

function PageWrapper({ children }: { children: ReactNode }) {
  return (
    <Container maxWidth="lg">
      <Box mt={6} mb={4}>
        <Typography data-cy="insights-title" variant="h1" mb={1}>
          Insights
        </Typography>
        <Typography data-cy="insights-description" variant="body1" fontWeight={400} color="textSecondary" mb={6}>
          Uncover optimization opportunities based on your cloud billing data.
        </Typography>
        {children}
      </Box>
    </Container>
  );
}

export function InsightsList() {
  const { insights, isFetching, nonEntitledSummary } = useInsights();
  const { isDoitEmployee } = useAuthContext();
  const theme = useTheme();
  const [sortOrder, setSortOrder] = useState(VALUE_DESCENDING);
  const { savingsPeriod, setSavingsPeriod } = useInsightsContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [filters, setFilters] = useState<Filters>({
    providers: [],
    categories: [],
    sources: [],
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    mixpanel.track("insights.list.open");
  }, []);

  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();

  const createNewInsight = () => {
    history.push(`${routeMatchURL}/new-insight`);
  };

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const changeSavingsPeriod = (event: SelectChangeEvent) => {
    const newValue = event.target.value as SavingsPeriod;
    mixpanel.track("insights.list.change-savings-period", {
      oldValue: savingsPeriod,
      newValue,
    });
    setSavingsPeriod(newValue);
  };

  const changeSortOrder = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    mixpanel.track("insights.list.change-sort-order", {
      oldValue: sortOrder,
      newValue,
    });
    setSortOrder(newValue);
  };

  const handleFilterChange = (category: keyof Filters, value: string) => {
    setFilters((prev) => {
      const currentValues = prev[category] as string[];
      const index = currentValues.indexOf(value);
      const newValues = index === -1 ? [...currentValues, value] : currentValues.filter((item) => item !== value);
      return {
        ...prev,
        [category]: newValues,
      };
    });
  };

  const SOURCE_MAP = useMemo(
    () => ({
      core: "DoiT Cloud Intelligence™",
      custom: "DoiT Experts",
      "bq-lens": "BigQuery Lens",
      other: "Other",
    }),
    []
  );

  const allSources = useMemo(() => {
    const sourceSet = new Set<string>(insights?.map((insight) => SOURCE_MAP[insight.providerId] || SOURCE_MAP.other));
    return Array.from(sourceSet);
  }, [insights, SOURCE_MAP]);

  const FILTER_CONFIGS: FilterConfig[] = [
    {
      title: "Provider",
      items: ALL_PROVIDERS,
      filterKey: "providers",
      getLabel: capitalizeProvider,
      filterFunction: (insight, item) => insight.cloudTags.includes(item as ProviderTag),
    },
    {
      title: "Category",
      items: ALL_CATEGORIES,
      filterKey: "categories",
      getLabel: (item) => item,
      filterFunction: (insight, item) =>
        insight.categories?.some((cat) => cat.toLowerCase() === item.toLowerCase()) ?? false,
    },
    {
      title: "Source",
      items: allSources,
      filterKey: "sources",
      getLabel: (item) => item,
      filterFunction: (insight, item) => (SOURCE_MAP[insight.providerId] || SOURCE_MAP.other) === item,
    },
  ];

  if (isFetching) {
    return (
      <PageWrapper>
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh" width="100%">
          <SyncLoader size={10} color={theme.palette.primary.main} loading />
        </Box>
      </PageWrapper>
    );
  }

  if (!insights?.length) {
    return (
      <PageWrapper>
        <NotFound />
      </PageWrapper>
    );
  }

  const nonEntitledDailySavings = nonEntitledSummary?.potentialDailySavings.value || 0;
  const customProblems = nonEntitledSummary?.customProblems.numberOfActionableInsights || 0;
  const potentialDailySaving = nonEntitledSummary?.potentialDailySavings.numberOfActionableInsights || 0;
  const nonEntitledTotalInsights = customProblems + potentialDailySaving;

  const filteredInsights = insights.filter((insight) => insight.status === "success");
  const sortedInsights = sortByTotalPotentialDailySavings(filteredInsights, sortOrder === VALUE_ASCENDING);

  interface TabConfig {
    label: string;
    filterFunction: (insight: Insight) => boolean;
  }

  const TAB_CONFIGS: TabConfig[] = [
    {
      label: "All",
      filterFunction: () => true,
    },
    {
      label: "Actionable",
      filterFunction: (insight) =>
        (insight.results?.isRelevant && !isOptimized(insight) && insight.userStatusChanges?.status !== "dismissed") ??
        false,
    },
    {
      label: "Optimized",
      filterFunction: (insight) => isOptimized(insight),
    },
    {
      label: "Dismissed",
      filterFunction: (insight) => !insight.results?.isRelevant || insight.userStatusChanges?.status === "dismissed",
    },
  ];

  const insightsByTab = TAB_CONFIGS.map((tabConfig) => sortedInsights.filter(tabConfig.filterFunction));

  const selectedInsights: (Insight | { isUpsell: true })[] = insightsByTab[selectedTab]
    .filter((insight) => {
      const searchText = searchQuery.toLowerCase();
      return (
        insight.title.toLowerCase().includes(searchText) ||
        insight.shortDescription.toLowerCase().includes(searchText) ||
        insight.detailedDescriptionMdx.toLowerCase().includes(searchText)
      );
    })
    .filter((insight) =>
      FILTER_CONFIGS.every(({ filterKey, filterFunction }) => {
        const currentFilterValues = filters[filterKey].map(String);
        if (currentFilterValues.length === 0) return true;
        return currentFilterValues.some((item) => filterFunction(insight, item));
      })
    );

  if (selectedTab === 1 && nonEntitledTotalInsights > 0 && selectedInsights.length > 0) {
    selectedInsights.splice(1, 0, { isUpsell: true });
  }

  return (
    <PageWrapper>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Box sx={{ width: { xs: "100%", md: 250 }, mb: { xs: 3, md: 0 }, mr: { md: 3 } }}>
          {FILTER_CONFIGS.map((config) => (
            <FilterSection
              key={config.title}
              config={config}
              filters={filters}
              handleFilterChange={handleFilterChange}
              insights={insights}
            />
          ))}
        </Box>
        <Box flexGrow={1}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search insights..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 1, minWidth: 250, maxWidth: 500 }}
            />
            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: { xs: 2, md: 0 } }}>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Savings period</InputLabel>
                <Select label="Savings period" value={savingsPeriod} onChange={changeSavingsPeriod}>
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Sort by</InputLabel>
                <Select label="Sort by" value={sortOrder} onChange={changeSortOrder}>
                  <MenuItem value={VALUE_DESCENDING}>Value high to low</MenuItem>
                  <MenuItem value={VALUE_ASCENDING}>Value low to high</MenuItem>
                </Select>
              </FormControl>
              {isDoitEmployee && (
                <Button size="large" variant="contained" color="primary" onClick={createNewInsight}>
                  {CREATE_CUSTOM_INSIGHT}
                </Button>
              )}
            </Stack>
          </Stack>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Insights Categories"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              minHeight: "48px",
              marginBottom: 3,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            {TAB_CONFIGS.map((tabConfig, index) => (
              <Tab
                key={tabConfig.label}
                label={`${tabConfig.label} (${insightsByTab[index].length})`}
                sx={{
                  textTransform: "none",
                }}
              />
            ))}
          </Tabs>

          {selectedInsights.length ? (
            <Stack direction="column" spacing={2}>
              {selectedInsights.map((item, index) =>
                "isUpsell" in item ? (
                  <InsightsListRowUpsell
                    key={`upsell-${index}`}
                    totalInsights={nonEntitledTotalInsights}
                    savings={nonEntitledDailySavings}
                  />
                ) : (
                  <InsightsListRow key={`${item.providerId}#${item.key}`} insight={item} />
                )
              )}
            </Stack>
          ) : (
            <NotFound message="We couldn't find any relevant insights with the selected filters." showIcon={false} />
          )}
        </Box>
      </Stack>
    </PageWrapper>
  );
}
