import { useEffect, useRef } from "react";

import { Stack } from "@mui/material";

import Title from "../Common/Title";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";

export const Header = () => {
  const { updateNode, nodeConfig } = useNodeConfigurationContext();
  const initialTitle = useRef<string | null>(null);
  const nodeId = useRef<string>(nodeConfig.id);

  useEffect(() => {
    if (initialTitle.current === null || nodeId.current !== nodeConfig.id) {
      initialTitle.current = nodeConfig.name;
      nodeId.current = nodeConfig.id;
    }
  }, [nodeConfig.id, nodeConfig.name]);

  const handleUpdateTitle = (title: string) => {
    updateNode({ name: title });
  };

  const handleBlurTitle = () => {
    if (!nodeConfig.name?.trim()) {
      updateNode({ name: initialTitle.current || "" });
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Title
        title={nodeConfig.name}
        onUpdateTitle={handleUpdateTitle}
        onBlur={handleBlurTitle}
        placeholder="Add Node name"
      />
    </Stack>
  );
};
