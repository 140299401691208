import { useEffect, useState } from "react";

import { UserModel } from "@doitintl/cmp-models/src";
import { getCollection } from "@doitintl/models-firestore/src";
import { Alert, type AlertColor } from "@mui/material";

import { isOptimized } from "../utils";
import type { Insight } from "../types";

type StatusDetails = {
  date: string;
  userDisplayName: string;
};

export const InsightStatusInfoBar = ({ insight }: { insight: Insight }) => {
  let severity: AlertColor | undefined = "info";
  let label = "";

  const [statusDetails, setStatusDetails] = useState<StatusDetails | null>(null);

  useEffect(() => {
    const fetchStatusDetails = async () => {
      const retrievedDetails = insight.userStatusChanges;

      if (!retrievedDetails?.userRef?.id) {
        return;
      }

      const userDoc = await getCollection(UserModel).doc(retrievedDetails.userRef.id).get();
      const user = userDoc.asModelData();

      if (retrievedDetails && user) {
        const details: StatusDetails = {
          date: retrievedDetails.timestamp.toDate().toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }),
          userDisplayName: user.displayName,
        };

        setStatusDetails(details);
      }
    };

    fetchStatusDetails();
  }, [insight]);

  if (insight.status === "error") {
    severity = "error";
    label = "Something went wrong, please try again later.";
  } else if (insight.userStatusChanges?.status === "dismissed") {
    severity = "info";
    if (statusDetails) {
      label = `This insight was dismissed by ${statusDetails.userDisplayName} on ${statusDetails.date}.`;
    } else {
      label = "This insight was dismissed.";
    }
  } else if (insight.userStatusChanges?.status === "optimized") {
    severity = "success";
    if (statusDetails) {
      label = `This insight was marked as optimized by ${statusDetails.userDisplayName} on ${statusDetails.date}.`;
    } else {
      label = "This insight was marked as optimized.";
    }
  } else if (!insight.results?.isRelevant) {
    severity = "info";
    label = "This insight was automatically dismissed by DoiT Cloud Intelligence™.";
  } else if (isOptimized(insight)) {
    severity = "success";
    label = "This insight is currently optimized and no further action is required.";
  }

  return <Alert severity={severity}>{label}</Alert>;
};
