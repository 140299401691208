import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Card, CardHeader, CardContent } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useCurrentDashboardContext } from "../../Context/CurrentDashboardContext";
import { useAuthContext } from "../../Context/AuthContext";
import { CARD_HEADER_HEIGHT, HEADER_PADDING, HEADING_VARIANT, SUBHEADING_VARIANT } from "./Analytics/cloudCardStyle";

const useStyles = makeStyles(() => ({
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
  },
  cardContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  icon: {
    fontSize: 40,
  },
}));

const ErrorCard = ({ error, widget }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isDoitEmployee } = useAuthContext();
  const { widgetHeight } = useCurrentDashboardContext();

  return (
    <Grid>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={widget?.name}
          titleTypographyProps={{ variant: HEADING_VARIANT }}
          subheader=""
          subheaderTypographyProps={{ variant: SUBHEADING_VARIANT }}
        />
        <CardContent style={{ height: 200 * widgetHeight }} className={classes.cardContent}>
          <ErrorIcon className={classes.icon} />
          <Typography variant="subtitle1">Oops! Something went wrong...</Typography>
          {isDoitEmployee && <pre style={{ backgroundColor: theme.palette.background.default }}>{error.message}</pre>}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ErrorCard;
