import { useState } from "react";

import { type Cluster } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { type AxiosError } from "axios";
import { useFormik } from "formik";

import { useApiContext } from "../../../api/context";
import FormItem from "../../../Components/FormItem";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { getAxiosErrorMessage } from "../../../utils/axios";
import { createCluster, updateCluster } from "../api";
import {
  CLUSTER_FORM,
  type ClusterFormValues,
  getCreateClusterPayload,
  getTrimmedValues,
  getUpdateClusterPayload,
} from "./clusterForm";
import TestConnection from "./TestConnection";

interface ClusterDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  cluster?: Cluster;
}

const ClusterDialog = ({ open, setOpen, cluster }: ClusterDialogProps) => {
  const isEditMode = !!cluster;

  const [loading, setLoading] = useState(false);

  const errorSnackbar = useErrorSnackbar();
  const { customer } = useCustomerContext();
  const api = useApiContext();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = async (values: ClusterFormValues) => {
    const trimmedValues = getTrimmedValues(values);

    setLoading(true);
    try {
      if (isEditMode) {
        const clusterPayload = getUpdateClusterPayload(trimmedValues);
        await updateCluster(api, clusterPayload, cluster.id);
      } else {
        const clusterPayload = getCreateClusterPayload(trimmedValues);
        await createCluster(api, clusterPayload, customer.id);
      }

      handleCloseDialog();
    } catch (e) {
      consoleErrorWithSentry(e);
      const message = getAxiosErrorMessage(e as AxiosError);
      errorSnackbar(message);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: CLUSTER_FORM.getInitialValues(cluster),
    onSubmit: handleSubmit,
    validationSchema: CLUSTER_FORM.getSchema(cluster),
    validateOnBlur: true,
    validateOnChange: true,
  });

  const isFormTouched = Object.keys(formik.touched).length > 0;

  const handleSave = async () => {
    const isValid = await formik.validateForm();
    if (!isValid) {
      return;
    }

    await formik.submitForm();
    formik.resetForm();
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>{isEditMode ? "Edit Self-managed K8s Cluster" : "Add Self-managed K8s Cluster"}</DialogTitle>
      <DialogContent>
        <Box width={400} sx={{ maxHeight: "100vh", overflow: "auto" }}>
          <form>
            <Stack spacing={2} py={1}>
              {CLUSTER_FORM.getFields(cluster).map((field) => (
                <FormItem<ClusterFormValues> key={field.name} name={field.name} field={field} formik={formik} />
              ))}
              <TestConnection formik={formik} />
            </Stack>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <LoadingButton
          onClick={handleSave}
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!isFormTouched || !formik.isValid}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClusterDialog;
