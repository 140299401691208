import { useEffect, useMemo } from "react";

import {
  ComparisonOperator,
  ModelType,
  type ReferencedNodeValue,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, useFormikContext } from "formik";
import type * as Yup from "yup";

import { cloudflowTexts } from "../../../../../assets/texts";
import { GenericForm } from "../../ApiActionParametersForm/ApiActionParametersForm";
import { ReferencedFieldContextProvider } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { ReferencedFieldStandalone } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { generateApiActionParametersSchema } from "../../ApiActionParametersForm/useApiActionParametersSchema";
import { operatorMap } from "../types";
import { getModelByFieldReference } from "../utils";
import { defaultSchema, type FilterDialogBaseProps } from ".";

interface FilterDialogFormProps extends Omit<FilterDialogBaseProps, "groupIndex" | "open"> {
  setNestedModel: (model: UnwrappedApiServiceModelDescriptor | undefined) => void;
  nestedModel?: UnwrappedApiServiceModelDescriptor;
  setValidationSchema: (schema: Yup.AnyObjectSchema) => void;
  mode: "add" | "edit";
}

const allOperators = Object.values(ComparisonOperator).map((operator) => ({
  label: operator,
  value: operator,
}));

interface FilterDialogFormValues {
  fieldReference: (ReferencedNodeValue & { type: ModelType }) | null;
  selectedOperator: ComparisonOperator | null;
  value: string;
}

export const FilterDialogForm: React.FC<FilterDialogFormProps> = ({
  referenceableNodes,
  mode,
  nestedModel,
  selectedNode,
  setNestedModel,
  handleClose,
  setValidationSchema,
}) => {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur, isValid } =
    useFormikContext<FilterDialogFormValues>();

  const operators = useMemo(() => {
    const type = nestedModel?.type;

    if (!type) {
      return [];
    }
    const availableOperators =
      operatorMap[type].map((operator) => ({
        label: operator,
        value: operator,
      })) || allOperators;
    if (values.selectedOperator && !availableOperators.some((op) => op.value === values.selectedOperator)) {
      setFieldValue("selectedOperator", null);
    }
    return availableOperators;
  }, [nestedModel?.type, setFieldValue, values.selectedOperator]);

  useEffect(() => {
    if (
      !selectedNode ||
      !values?.fieldReference?.referencedField ||
      values.fieldReference.referencedField.length === 0
    ) {
      setNestedModel(undefined);
      return;
    }
    const model = getModelByFieldReference(selectedNode.outputModel, values.fieldReference.referencedField);
    if (model?.type === ModelType.STRUCTURE || model?.type === ModelType.LIST) {
      setNestedModel(undefined);
      return;
    }

    setNestedModel(model);
  }, [values?.fieldReference?.referencedField, setNestedModel, selectedNode]);
  useEffect(() => {
    if (values.fieldReference) {
      const fieldPath = (values.fieldReference.referencedField || []).join(".");

      if (!nestedModel?.type || !selectedNode?.outputModel?.type) {
        setValidationSchema(defaultSchema);
        return;
      }

      const valueValidation = generateApiActionParametersSchema(
        nestedModel,
        { referenceableNodes, model: selectedNode.outputModel },
        "Value",
        true,
        fieldPath
      );

      const newValidationSchema = defaultSchema.shape({
        value: valueValidation,
      });

      setValidationSchema(newValidationSchema);
    }
  }, [nestedModel, referenceableNodes, selectedNode?.outputModel, setValidationSchema, values.fieldReference]);

  return (
    <Form>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={3}>
            <Typography variant="subtitle1" fontWeight={500}>
              {cloudflowTexts.SELECT_FIELD_TO_FILTER}
            </Typography>
            <ReferencedFieldStandalone
              value={values.fieldReference}
              name="fieldReference"
              label={cloudflowTexts.REFERENCED_FIELD}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              helperText={touched.fieldReference && errors.fieldReference ? errors.fieldReference : ""}
              error={Boolean(touched.fieldReference && errors.fieldReference)}
              referenceableNodes={selectedNode ? [selectedNode] : []}
            />
          </Stack>

          <Stack spacing={3}>
            <Typography variant="subtitle1" fontWeight={500}>
              {mode === "add" ? cloudflowTexts.DEFINE_FILTER_INPUT : cloudflowTexts.DEFINE_FILTER_CRITERIA}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <Tooltip
                title={!nestedModel ? cloudflowTexts.SELECT_VALUE_FROM_REFERENCE_FIELD : ""}
                placement="top-start"
              >
                <TextField
                  select
                  label={cloudflowTexts.OPERATOR}
                  name="selectedOperator"
                  value={values.selectedOperator}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  required
                  disabled={!nestedModel}
                  sx={{ minWidth: 160 }}
                  error={touched.selectedOperator && Boolean(errors.selectedOperator)}
                  helperText={touched.selectedOperator && errors.selectedOperator}
                >
                  {operators.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
              <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
                <GenericForm
                  inputModel={nestedModel}
                  fieldPath={"value"}
                  label="Value"
                  placeholderTooltip={cloudflowTexts.SELECT_VALUE_FROM_REFERENCE_FIELD}
                />
              </ReferencedFieldContextProvider>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cloudflowTexts.CANCEL}
        </Button>
        <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
          {mode === "add" ? cloudflowTexts.ADD_FILTER : cloudflowTexts.EDIT_FILTER}
        </Button>
      </DialogActions>
    </Form>
  );
};
