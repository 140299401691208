export const ACTUAL = "Actual";
export const ACTUALS = "Actuals";
export const BUDGET_AMOUNT = "Budget amount";
export const BUDGET_AMOUNT_TO_DATE = "Budget amount to date";
export const FORECAST = "Forecast";
export const FORECASTED = "Forecasted";

export const budgetTxt = {
  ACTUAL: "Actual",
  ACTUALS: "Actuals",
  ALL_CHANGES_ARE_SAVED: "All changes are saved",
  BUDGET_NAME: "Budget name",
  BUDGET_AMOUNT: "Budget amount",
  BUDGET_AMOUNT_TO_DATE: "Budget amount to date",
  SAVE_NEW: "Save new budget as",
  SUCCESSFULLY_SAVED: "Budget saved successfully",
  SUCCESSFULLY_CLONED: "Budget cloned successfully",
  DUPLICATE_SUCCESSFUL: "Budget duplicated successfully",
  FAILED_TO_CLONE: "Failed cloning budget",
  FAILED_TO_UPDATE: "Failed saving budget",
  SAVE_AS_LABEL: "Name",
  SAVE_AS_HELPER_TEXT: "This will create a new budget with you as the owner",
  NAME_BEFORE_SAVING: "Name before saving",
  BUDGET_IS_INVALID:
    "A budget must contain a name, a scope, an amount, an alert threshold and a collaborator (or a Slack channel)",
  VIEW_BUDGET: "View budget",
  CREATE_BUDGET: {
    SUBMIT_BTN: "Create budget",
    STEP_1: {
      TITLE: "Budget scope",
    },
    STEP_2: {
      TITLE: "Configuration",
    },
    STEP_3: {
      TITLE: "Notification thresholds",
      PERCENTAGE: "Percentage",
      BUDGET_AMOUNT: "Budget amount",
      NOTIFICATION_SETTINGS: "Notification settings",
      EMAIL_INPUT_LABEL: "Send email alerts to the following accounts when thresholds are met",
      ALERT_THRESHOLD_CROSSED: "This threshold has already been crossed for the current ",
    },
    INFO_PANEL: {
      TYPE_AND_FREQUENCY: "Type and frequency",
      DIMENSIONS: "Dimensions",
      SCOPE: "Scope",
      START_DATE: "Start date",
      END_DATE: "End date",
      EDIT_BUDGET_SCOPE: "Edit budget scope",
    },
    BUDGET_AMOUNT: "Budget amount",
    LAST_PERIOD_ACTUAL_COST: "Last period actual cost",
    BUDGET_CONFIGURATION: "Budget configuration",
    BUDGET_CONFIGURATION_SINGLE_PERIOD: "Allow the budget to track against a single period budgeted amount",
    BUDGET_CONFIGURATION_DYNAMIC: "Allow dynamic adjustments of your budgeted amount",
    BUDGET_CONFIGURATION_SPECIFY: "Specify your budgeted amount for each budget period",
    BUDGET_CONFIGURATION_DYNAMIC_LAST_PERIOD: "Budgeted amount is based on the last period amount",
    BUDGET_CONFIGURATION_DYNAMIC_PERCENTAGE_GROWTH: "Allow % growth in recurring period budgeted amounts",
    BUDGET_CONFIGURATION_DYNAMIC_COMBINATION:
      "Budget amount is based on the last period amount AND allow % growth in recurring period budgeted amounts",
    BUDGET_CONFIGURATION_DYNAMIC_NEXT_PERIOD_ROLLOVER: "Allow for excess to rollover to the next period",
    USE_AMORTIZED_COST: "Use amortized cost",
    BUDGET_PREVIEW: "Budget preview",
    GROWTH: "Growth %",
    ADVANCED_SETTINGS: "Advanced settings",
    ROLLOVER_MONTHS_TITLE: "Select the number of months you would like the excess to rollover and accumulate",
  },
  ACTUALS_TO_DATE: "Actuals to date:",
  BUDGET_AMOUNT_TERM: "Budget amount:",
  TYPE_AND_FREQUENCY: "Type and frequency:",
  DIMENSION: "Dimension:",
  BUDGET_AMOUNT_TO_DATE_TERM: "Budget amount to date:",
  VARIANS_TO_DATE: "Variance to date:",
  FORECAST: "Forecast",
  FORECASTED: "Forecasted",
  FORECASTED_AMOUNT_FOR_ENTIRE_PERIOD: "Forecasted amount for entire period:",
  FORECASTED_TO_HIT_BUDGET_AMOUNT: "Forecasted to hit budget amount:",
  NO_FORECASTED_DATE: "No Forecasted date",
  DATA_LOADING: "Data loading...",
  CHART_LOADING: "Chart loading...",
  NEW_BUDGET: "New Budget",
  EDIT_BUDGET: "Edit Budget",
  THRESHOLD_TRIGGER: "Threshold Trigger",
  BUDGET_SUMMARY: "Budget summary",
  CURRENT_PERIOD: "Current period",
  DATA_AVAILABLE_SOON: "Data available soon",
};
