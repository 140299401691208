import { useState } from "react";

import { Button } from "@mui/material";
import type { CustomerFeedback } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import AnomalyFeedbackDialog from "./AnomalyFeedbackDialog";

type Props = {
  customerFeedback?: CustomerFeedback;
};

export const AnomalyFeedbackButton = ({ customerFeedback }: Props) => {
  const [open, setOpen] = useState(false);
  const isEntitledAcknowledgement = useIsFeatureEntitled("governance:costAnomalies:acknowledgement");
  const { customer } = useCustomerContext();
  const isInPresentationMode = isCustomerInPresentationMode(customer);

  if (!isEntitledAcknowledgement && !isInPresentationMode) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
      >
        Acknowledge anomaly
      </Button>
      <AnomalyFeedbackDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        customerFeedback={customerFeedback}
      />
    </>
  );
};
