import { type INodeModel } from "@doitintl/cmp-models/src/Cloudflow";
import { type Node } from "@xyflow/react";

import { type HandleUpdateNodeFn, type RFNode, type UpdateNodeData } from "../../types";

/**
 * Updates the selected node with the partial overwritten data provided by either
 * the object or object from the updater function.
 *
 * Automatically handles node status based on node errors unless status is explicitly provided
 * */
export function updateSelectedNode(node: Node<RFNode>, updateNodeAction: Parameters<HandleUpdateNodeFn>[0]) {
  let updatedNodeConfig: UpdateNodeData;

  if (updateNodeAction instanceof Function) {
    const upd: UpdateNodeData = {
      name: node.data.nodeData.name,
      parameters: node.data.nodeData.parameters,
      errors: node.data.errors,
      status: node.data.nodeData.status,
      approval: node.data.nodeData.approval,
      statusMessage: node.data.nodeData.statusMessage,
    };
    updatedNodeConfig = updateNodeAction(upd);
  } else {
    updatedNodeConfig = updateNodeAction;
  }

  const { errors, ...updatedNodeData } = updatedNodeConfig;

  const status = updatedNodeData.status
    ? updatedNodeData.status
    : Object.keys(errors ?? node.data.errors).length
      ? "error"
      : "validated";

  const nodeData = {
    ...node.data.nodeData,
    ...updatedNodeData,
    status,
  } satisfies INodeModel;

  const updatedNode: Node<RFNode> = {
    ...node,
    data: {
      ...node.data,
      ...(errors ? { errors } : undefined),
      nodeData,
      touched: true,
    },
  };

  return updatedNode;
}
