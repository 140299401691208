import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import ClusterDialog from "./ClusterDialog/ClusterDialog";

const ClusterPageHeader = () => {
  const [clusterDialogIsOpen, setClusterDialogIsOpen] = useState(false);

  const openClusterDialog = () => {
    setClusterDialogIsOpen(true);
  };

  return (
    <>
      <ClusterDialog open={clusterDialogIsOpen} setOpen={setClusterDialogIsOpen} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h1" display="inline">
          Clusters
        </Typography>
        <Button onClick={openClusterDialog} startIcon={<AddIcon />} variant="contained" color="primary">
          Add Cluster
        </Button>
      </Stack>
    </>
  );
};

export default ClusterPageHeader;
