import { type Dispatch, type FC, useEffect, useState } from "react";

import { useFormikContext } from "formik";

export const FormChangesListener: FC<{
  onValuesChange?: (parameters: any) => void;
  onValidityChange?: Dispatch<boolean>;
}> = ({ onValuesChange, onValidityChange }) => {
  const { dirty, isValid, isValidating, values } = useFormikContext();
  const [changedValues, setChangedValues] = useState(values);
  const [changedValidity, setChangedValidity] = useState(isValid);

  useEffect(() => {
    dirty && setChangedValues(values);
  }, [dirty, values]);

  useEffect(() => {
    !isValidating && setChangedValidity(isValid);
  }, [isValid, isValidating]);

  useEffect(() => onValuesChange?.(changedValues), [changedValues, onValuesChange]);
  useEffect(() => onValidityChange?.(changedValidity), [changedValidity, onValidityChange]);

  return null;
};
